import React, {useState,useEffect} from 'react';
import styles from './add.module.css';
import { Breadcrumbs  } from '@mui/material';
import {  useNavigate,Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import { FromLoader } from  '../../components/LoaderC';
import Loader from  '../../components/Loader';
import withAdminAuth from "../../components/withAdminAuth";
import {authData} from "../../components/getAuth";
function ContactAdmin({ history, match }) {
  const auth = authData();
  const audience = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const schema = yup.object().shape({
    office_time_title: yup.string().required('This field is Required'),
    address: yup.string().required('This field is Required'),
    email: yup.string().required('This field is Required').email('Invalid email'),
    phone_1: yup.string().required('This field is Required'),   
    phone_2: yup.string().required('This field is Required'),
    business_time_title: yup.string().required('This field is Required'),
    open_time: yup.string().required('This field is Required'),
    close_time: yup.string().required('This field is Required'),
  });
  
  const { register, handleSubmit, watch,setValue,  formState: { errors } } = useForm({
    resolver: yupResolver(schema), mode: 'onChange'
  });

  useEffect(()=>{

      if(contentLoading){
        axios.get(audience + 'v1/page-manager/contact', {
          headers: {
            Authorization: `Bearer ${auth?.api_token}`,
          }
        }).then(response => {
          setContentLoading(false);
          let itemData = response.data.data;
          itemData = JSON.parse(itemData.description);
          setValue('office_time_title',itemData.office_time_title);
          setValue('address', itemData.address);
          setValue('email', itemData.email);
          setValue('phone_1', itemData.phone_1);
          setValue('phone_2', itemData.phone_2);
          setValue('business_time_title', itemData.business_time_title);
          setValue('open_time', itemData.open_time);
          setValue('close_time', itemData.close_time);

         // setEditorVal(itemData.description);
        }).catch(error => {
          setContentLoading(false);
        });
      }
    
  },[audience,setValue,contentLoading,auth]);
  
  async function onSubmit(data) {
    let postData = {
      title: 'contact',
      description: JSON.stringify(data),
    }
    setLoading(true);
      return axios.post(audience + 'v1/page-manager', postData, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then(response => {
        setLoading(false);
        toast.success(response.data.message);
        navigate('/admin/page-manager/contact');
      }).catch(error => {
        setLoading(false);
      });

  }
  return (
      <React.Fragment>
        {loading && <Loader /> }
        {contentLoading && <div className="LoaderCard"><div className="Loader FromLoader"><FromLoader /></div></div> }
        {!contentLoading && <div className={`${styles.MainDiv}`}>
          <div className={`${styles.PageTitleWrap}`}>
            <p className={`${styles.PageTitle}`}>Contact Us</p>
            <Breadcrumbs aria-label="breadcrumb" className='breadcrumb'>
              <Link underline="hover" color="inherit" to="/admin/dashboard">Dashboard</Link>
              {/* <Link underline="hover" color="inherit" to={'/admin/faq/list'}>FAQs</Link>
              <Typography color="text.primary">{isAddMode?'Add':'Edit'}</Typography> */}
            </Breadcrumbs>
          </div>
          
          <div className={`${styles.MainCard}`}>
            <div className={`${styles.AddUserMain}`}>
              <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <span>Office Address</span>
              <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroupFull}`}>
                    <label className={`${styles.FormLabel}`}>Title*</label>
                    <input {...register("office_time_title")} className={`${styles.FormControl}`} />
                                  
                    {errors.office_time_title && !watch().office_time_title && <span className={`${styles.ErrorM}`}>{errors.office_time_title.message}</span>}
                  </div>
                </div>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroupFull}`}>
                    <label className={`${styles.FormLabel}`}>Address*</label>
                    <input {...register("address")} className={`${styles.FormControl}`} />                                  
                    {errors.address && !watch().address && <span className={`${styles.ErrorM}`}>{errors.address.message}</span>}
                  </div>
                </div>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroupFull}`}>
                    <label className={`${styles.FormLabel}`}>Email*</label>
                    <input {...register("email")} className={`${styles.FormControl}`} />
                                  
                    {errors.email && !watch().email && <span className={`${styles.ErrorM}`}>{errors.email.message}</span>}
                  </div>
                </div>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroupFull}`}>
                    <label className={`${styles.FormLabel}`}>Phone Number 1*</label>
                    <input {...register("phone_1")} className={`${styles.FormControl}`} />
                                  
                    {errors.phone_1 && !watch().phone_1 && <span className={`${styles.ErrorM}`}>{errors.phone_1.message}</span>}
                  </div>
                </div>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroupFull}`}>
                    <label className={`${styles.FormLabel}`}>Phone Number 2*</label>
                    <input {...register("phone_2")} className={`${styles.FormControl}`} />
                                  
                    {errors.phone_2 && !watch().phone_2 && <span className={`${styles.ErrorM}`}>{errors.phone_2.message}</span>}
                  </div>
                </div>
                </div>
                <div>
                <span>Business Hours</span>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroupFull}`}>
                    <label className={`${styles.FormLabel}`}>Title*</label>
                    <input {...register("business_time_title")} className={`${styles.FormControl}`} />
                                  
                    {errors.business_time_title && !watch().business_time_title && <span className={`${styles.ErrorM}`}>{errors.business_time_title.message}</span>}
                  </div>
                </div>
              <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroupFull}`}>
                    <label className={`${styles.FormLabel}`}>Open Time*</label>
                    <input {...register("open_time")} className={`${styles.FormControl}`} />
                                  
                    {errors.open_time && !watch().open_time && <span className={`${styles.ErrorM}`}>{errors.open_time.message}</span>}
                  </div>
                </div>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroupFull}`}>
                    <label className={`${styles.FormLabel}`}>Close Time*</label>
                    <input {...register("close_time")} className={`${styles.FormControl}`} />                                  
                    {errors.close_time && !watch().close_time && <span className={`${styles.ErrorM}`}>{errors.close_time.message}</span>}
                  </div>
                </div>
                </div>
                             
              <div className={`${styles.FormButtonArea}`}>
                {/* <button className={`${styles.CancelBU}`} type="button">Cancel</button> */}
                <input className={`${styles.SubmitBU}`} value="Update" type="submit" />
              </div>
            </form>
          </div>

        </div>
        </div>}
      </React.Fragment>
  )}


export default withAdminAuth(ContactAdmin);