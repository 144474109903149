import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  FrontRouteList,
  WithoutThemeRouteList,
  AdminRoutes,
  CustomerRoutes,
} from "./index";
import AdminLayout from "../layouts/AdminLayout";
import FrontLayout from "../layouts/FrontLayout";
import CustomerLayout from "../layouts/CustomerLayout";
import Page404 from "../frontend/components/Page404";
import ScrollToTop from "../frontend/components/ScrollToTop";

const MainRoutes = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        {WithoutThemeRouteList?.map(({ component: Component, path }, index) => {
          return (
            <Route key={index} path={path} exact element={<Component />} />
          );
        })}

        {FrontRouteList?.map(({ component: Component, path }, index) => {
          return (
            <Route
              key={index}
              path={path}
              exact
              element={
                <FrontLayout>
                  <Component />
                </FrontLayout>
              }
            />
          );
        })}

        {AdminRoutes?.routeList?.map(
          ({ component: Component, path }, index) => {
            return (
              <Route
                key={index}
                path={`/admin${path}`}
                exact
                element={
                  <AdminLayout>
                    <Component />
                  </AdminLayout>
                }
              />
            );
          }
        )}

        {CustomerRoutes?.routeList?.map(
          ({ component: Component, path }, index) => {
            return (
              <Route
                key={index}
                path={`/customer${path}`}
                exact
                element={
                  <CustomerLayout>
                    <Component />
                  </CustomerLayout>
                }
              />
            );
          }
        )}

        <Route
          path="*"
          element={
            <FrontLayout>
              <Page404 />
            </FrontLayout>
          }
        />
      </Routes>
    </Router>
  );
};

export default MainRoutes;
