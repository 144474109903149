import React, {useState,useEffect} from 'react';
import styles from './add.module.css';
import { Typography, Breadcrumbs,Button  } from '@mui/material';
import {  useNavigate,Link ,useParams} from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import { NumericFormat } from 'react-number-format';
import { toast } from 'react-smart-toaster';
import { FromLoader } from  '../../components/LoaderC';
import Loader from  '../../components/Loader';
import withAdminAuth from "../../components/withAdminAuth";
import {authData} from "../../components/getAuth";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Select from "react-select";


function CouponAdd({ history, match }) {
  const auth = authData();
  const APIToken = auth?.api_token;
  const navigate = useNavigate();
  let { id } = useParams();
  const isAddMode = !id;
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [carList, setCarList] = useState([]);
  const [addonList, setAddonList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [defaultItem, setDefaultItem] = useState({});

  const schema = yup.object().shape({
    code: yup.string().required('This field is Required').max(50, 'Code cannot be more than 50 characters'),
    discount: yup.string().required('This field is Required')
  });
  
  const { register, handleSubmit, control,watch,setValue,  formState: { errors } } = useForm({
    resolver: yupResolver(schema), mode: 'onChange'
  });

  useEffect(()=>{
    axios.get(process.env.REACT_APP_API_URL + 'v1/car-list').then(response => {
      setCarList(response.data.data);
    });
  },[]);

  useEffect(()=>{
    axios.get(process.env.REACT_APP_API_URL + 'v1/add-on-list').then(response => {
      setAddonList(response.data.data);
    });
  },[]);

  useEffect(()=>{
    let carOptions =  carList.map(i=>{
      return {value:i.id,label:i.title,type:'car'};
    });
    let addOnOptions =  addonList.map(i=>{
      return {value:i.id,label:i.title,type:'addOn'};
    });

    let itemOptions = [
      {
        label: 'Cars',
        options: carOptions,
      },
      {
        label: 'Add Ons',
        options: addOnOptions,
      },
    ];

    setItemList(itemOptions);

  },[carList,addonList]);

  useEffect(()=>{
    if(!isAddMode){
      if(contentLoading){
        axios.get(process.env.REACT_APP_API_URL + 'v1/coupons/'+id, {
          headers: {
            Authorization: `Bearer ${APIToken}`,
          }
        }).then(response => {
          setContentLoading(false);
          let itemData = response.data.data;

          setValue('code',itemData.code);
          setValue('discount',itemData.discount);

          if (itemData.description !== undefined && itemData.description !== null) {
            setValue('description',itemData.description);
          }

          if (parseInt(itemData.limit) > 0) {
            setValue('limit',itemData.limit);
          }

          if (itemData.start_date !== undefined && itemData.start_date !== null) {
            setValue('start_date', moment(itemData.start_date).toDate());
          }

          if (itemData.end_date !== undefined && itemData.end_date !== null) {
            setValue('end_date', moment(itemData.end_date).toDate());
          }

          if(itemData.car !== null){
            setDefaultItem({value:itemData?.car?.id,label:itemData?.car?.title,type:'car'});
          }

          if(itemData.addon !== null){
            setDefaultItem({value:itemData?.addon?.id,label:itemData?.addon?.title,type:'addOn'});
          }

          
        }).catch(error => {
          setContentLoading(false);
        });
      }
    }else{
      setContentLoading(false);
    }
  },[isAddMode,id,setValue,contentLoading,APIToken]);

  async function onSubmit(data) {
    let formData = new FormData();
    Object.keys(data).map((key) => {
      if(typeof(data[key]) !== 'undefined'){
        if(key === 'start_date' || key === 'end_date'){
          formData.append(key, moment(data[key]).format('YYYY-MM-DD'));
        }else if(key === 'type'){
          formData.append('item_type', data[key].type);
          formData.append('item_id', data[key].value);
        }else{
          formData.append(key, data[key]);
        }
      }
      return true;
    });

    const postData = formData;
    setLoading(true);

    if(isAddMode){
      return axios.post(process.env.REACT_APP_API_URL + 'v1/coupons', postData, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then(response => {
        setLoading(false);
        toast.success(response.data.message);
        navigate('/admin/coupon/list');
      }).catch(error => {
        setLoading(false);
      });
    }else{
      return axios.post(process.env.REACT_APP_API_URL + 'v1/coupons/'+id, postData, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then(response => {
        setLoading(false);
        toast.success(response.data.message);
        navigate('/admin/coupon/list');
      }).catch(error => {
        setLoading(false);
      });
    }
  }

  const TypeField = React.forwardRef((field, ref) => {
    return (<Controller
      control={control}
      options={itemList}
      defaultValue={defaultItem}
      {...register('type')}
      render={({ field }) => (
        <Select {...field} options={itemList}  />
      )}
    />)
  })

  return (
      <React.Fragment>
        {loading && <Loader /> }
        {contentLoading && <div className="LoaderCard"><div className="Loader FromLoader"><FromLoader /></div></div> }
        {!contentLoading && <div className={`${styles.MainDiv}`}>
          <div className={`${styles.PageTitleWrap}`}>
            <p className={`${styles.PageTitle}`}>Coupon {isAddMode?'Add':'Edit'}</p>
            <Breadcrumbs aria-label="breadcrumb" className='breadcrumb'>
              <Link underline="hover" color="inherit" to="/admin/dashboard">Dashboard</Link>
              <Link underline="hover" color="inherit" to={'/admin/coupon/list'}>Coupon</Link>
              <Typography color="text.primary">{isAddMode?'Add':'Edit'}</Typography>
            </Breadcrumbs>
          </div>
          
          <div className={`${styles.MainCard}`}>
            <div className={`${styles.AddUserMain}`}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>Coupon Code*</label>
                    <input {...register("code")} className={`${styles.FormControl}`} />
                    {errors.code && !watch().code && <span className={`${styles.ErrorM}`}>{errors.code.message}</span>}
                  </div>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>Discount(%)*</label>
                    <Controller
                      control={control}
                      name="discount"
                      className={`${styles.FormControl}`}
                      render={({ field: { onChange, name, value } }) => (
                        <NumericFormat
                          decimalScale={0} 
                          name={name}
                          value={value}
                          onChange={onChange}
                          className={`${styles.FormControl}`}
                        />
                      )}
                    />
                    {errors.discount && !watch().discount && <span className={`${styles.ErrorM}`}>{errors.discount.message}</span>}
                  </div>
                </div>

              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormGroup}`}>
                  <label className={`${styles.FormLabel}`}>Start Date (<span className={`${styles.SpanText}`}>Keep blank for no limit</span>)</label>
                  <Controller
                    control={control}
                    name="start_date"
                    className={`${styles.FormControl}`}
                    render={({ field }) => (
                      <ReactDatePicker
                        className={`${styles.FormControl}`}
                        onChange={(e) => field.onChange(e)}
                        selected={field.value}
                      />
                    )}
                  />
                  {errors.start_date && <span className={`${styles.ErrorM}`}>{errors.start_date.message}</span>} 
                </div>
                <div className={`${styles.FormGroup}`}>
                  <label className={`${styles.FormLabel}`}>End Date (<span className={`${styles.SpanText}`}>Keep blank for no limit</span>)</label>
                  <Controller
                    control={control}
                    name="end_date"
                    className={`${styles.FormControl}`}
                    render={({ field }) => (
                      <ReactDatePicker
                        className={`${styles.FormControl}`}
                        onChange={(e) => field.onChange(e)}
                        selected={field.value}
                      />
                    )}
                  />
                  {errors.end_date && <span className={`${styles.ErrorM}`}>{errors.end_date.message}</span>}
                </div>
              </div>

              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormGroup}`}>
                  <label className={`${styles.FormLabel}`}>Use Limit (<span className={`${styles.SpanText}`}>Keep blank for no limit</span>)</label>
                  <Controller
                    control={control}
                    name="limit"
                    className={`${styles.FormControl}`}
                    render={({ field: { onChange, name, value } }) => (
                      <NumericFormat
                        isAllowed={(values, sourceInfo) => {
                          const { value } = values;
                          return value < 100;
                        }}
                        name={name}
                        value={value}
                        onChange={onChange}
                        className={`${styles.FormControl}`}
                        decimalScale={0} 
                      />
                    )}
                  />

                  {errors.limit && <span className={`${styles.ErrorM}`}>{errors.limit.message}</span>} 
                </div>
                <div className={`${styles.FormGroup}`}>
                  <label className={`${styles.FormLabel}`}>Type (<span className={`${styles.SpanText}`}>Keep blank for no type</span>)</label>
                  <TypeField />
                </div>
              </div>

              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormGroupFull}`}>
                  <label className={`${styles.FormLabel}`}>Description</label>
                  <textarea {...register("description")} className={`${styles.FormControlTextArea}`} rows={3}></textarea>
                  {errors.description && !watch().description && <span className={`${styles.ErrorM}`}>{errors.description.message}</span>}
                </div>
              </div>
              
             
                             
              <div className={`${styles.FormButtonArea}`}>
                <Link to={`/admin/coupon/list`} as={Button} className={`${styles.CancelBU}`}>Cancel</Link>
                <input className={`${styles.SubmitBU}`} value="Submit" type="submit" />
              </div>
            </form>
          </div>

        </div>
        </div>}
      </React.Fragment>
  )}


export default withAdminAuth(CouponAdd);