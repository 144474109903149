import React, { useEffect, useState } from "react";
import styles from "./cars.module.css";
import { Parallax } from "react-parallax";
import axios from "axios";
import withNavigateHook from "../../../customer/components/withNavigateHook";
import Carousel from "react-multi-carousel";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet2: {
    breakpoint: { max: 767, min: 300 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const Cars = (props) => {
  const navigate = useNavigate();
  const [carList, setCarList] = useState([]);
  const [allCarList, setAllCarList] = useState([]);
  const [currency, setCurrency] = useState("");
  const [currencyRate, setCurrencyRate] = useState(1);
  const [currencyLoading, setCurrencyLoading] = useState(false);
  const [isCompactCarList, setIsCompactCarList] = useState(true);
  const [isSuvCarList, setIsSuvCarList] = useState(false);
  const [isVanCarList, setIsVanCarList] = useState(false);
  const [carListText, setCarListText] = useState("Perfect for city driving and daily commutes, our compact cars comfortably seat five passengers. These vehicles are fuel-efficient and easy to maneuver, making them ideal for both short trips and long drives around Samoa.");

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (event.target.getAttribute("cngCurrency")) {
        let currentCurrency = localStorage.getItem("currentCurrency");
        if (currentCurrency) {
          setCurrency(currentCurrency);
          getCurrencyRate(currentCurrency);
        } else {
          setCurrency("USD");
          getCurrencyRate("USD");
        }
      }
    };

    window.removeEventListener("click", handleDocumentClick);
    window.addEventListener("click", handleDocumentClick, { passive: true });
    return () => window.removeEventListener("click", handleDocumentClick);
  }, []);

  useEffect(() => {
    let currentCurrency = localStorage.getItem("currentCurrency");
    if (currentCurrency) {
      setCurrency(currentCurrency);
      getCurrencyRate(currentCurrency);
    } else {
      setCurrency("USD");
      getCurrencyRate("USD");
    }
  }, []);

  useEffect(() => {
    axios(process.env.REACT_APP_API_URL + "v1/car-list").then((response) => {
      setAllCarList(response.data.data);
      let filteredCar = response.data.data.filter((item) => item.type === "Compact");
      setCarList(filteredCar);
    }).catch((error) => {
      setAllCarList([]);
    });
  }, []);
  
  const carListOpen = (event) => {
    let allCarlist = allCarList;
    const filteredCars = allCarlist.filter((item) => item.type === event);
    setCarList(filteredCars);
    if (event === "Compact") {
      setIsCompactCarList(true);
      setIsSuvCarList(false);
      setIsVanCarList(false);

      setCarListText("Perfect for city driving and daily commutes, our compact cars comfortably seat five passengers. These vehicles are fuel-efficient and easy to maneuver, making them ideal for both short trips and long drives around Samoa.");
    }
    if (event === "SUV") {
      setIsCompactCarList(false);
      setIsSuvCarList(true);
      setIsVanCarList(false);

      setCarListText("For those seeking higher ground clearance and a bit more space, our five-seat SUVs are the perfect choice. These vehicles provide a comfortable and safe driving experience, whether you're navigating urban streets or crossroads.");
    }
    if (event === "Mini Van") {
      setIsCompactCarList(false);
      setIsSuvCarList(false);
      setIsVanCarList(true);

      setCarListText("Designed for larger groups, our vans accommodate 7-8 passengers. These spacious vehicles are perfect for family outings, group trips, or any occasion requiring extra seating capacity.");
    }
  }
  
  const getCurrencyRate = (selectcurrency) => {
    setCurrency(selectcurrency);
    setCurrencyLoading(true);
    axios(process.env.REACT_APP_API_URL + "v1/currency-convertter/WST/" + selectcurrency).then((response) => {
      setCurrencyLoading(false);
      setCurrencyRate(response.data.data);
      setCurrencyRate(response.data.data);
    }).catch((error) => {
      setCurrencyLoading(false);
    });
  }
  
  const goToBookNow = () => {
    navigate("/reservation");
  }
  
  return (<React.Fragment>
    <div className="InnerBanner">
      <Parallax bgImage="/images/car-service-banner.jpg" strength={300}>
        <div style={{ height: 350 }} className={`${styles.BannerConArea}`}>
          <div className="container">
            <div className={`${styles.InnerBannerRow}`}>
              <h2 className={`${styles.InnerBannerTitle}`}>Cars</h2>
            </div>
          </div>
        </div>
      </Parallax>
    </div>
    <div className={`${styles.CarsAboutSec}`}>
      <div className="container">
        <p className={`${styles.AboutTitle}`}>Motu Car Rentals</p>
        <p className={`${styles.AboutSubTitle}`}>Samoa’s Reliable and Trusted Car Rental Service</p>
        <p className={`${styles.AboutText}`}>Looking for the top car rental option in Samoa? Motu Car Rentals has you covered! Whether you’re traveling solo, with family, or in a large group, Motu Car Rentals has the rental car for your trip. We specialize in offering three types of rental cars to meet your travel needs: compact cars (5 seats), SUVs (5 seats), and vans (7-8 seats). All our vehicles are reliable Toyota models, known for their quality and performance, imported directly from Japan.</p>
      </div>
    </div>
    <div className={`${styles.CarsCateSec}`}>
      <div className="container">
        <p className={`${styles.CarsCateSecTitle}`}>Our Fleet</p>
        <div className={`${styles.CardTabBUSec}`}>
          <button onClick={carListOpen.bind(this, "Compact")} className={isCompactCarList === true ? `${styles.Active}` : ""}>
            <img src={"/images/compact-car.svg"} alt="" />
            Compact Cars
          </button>
          <button onClick={carListOpen.bind(this, "SUV")} className={isSuvCarList === true ? `${styles.Active}` : ""}>
            <img src={"/images/suv.svg"} alt="" />
            SUVs
          </button>
          <button onClick={carListOpen.bind(this, "Mini Van")} className={isVanCarList === true ? `${styles.Active}` : ""}>
            <img src={"/images/van.svg"} alt="" />
            Vans
          </button>
        </div>
        <div className={`${styles.CarsCateSecCompact}`}>
          <p className={`${styles.CarsCateSecSubText}`}>{carListText}</p>
          <div className={`${styles.ProductSlider} ProductSlider`}>
            <Carousel
              swipeable={true}
              arrows={true}
              draggable={true}
              showDots={false}
              responsive={responsive}
              ssr={true}
              infinite={true}
              autoPlay={false}
              autoPlaySpeed={4000}
              keyBoardControl={true}
              customTransition="2000ms all"
              transitionDuration={500}
              containerClass="carousel-container"
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
              {carList?.map((item, index) => {
                return (<div className={`${styles.Item}`} key={index}>
                  <div className={`${styles.ItemUnder}`}>
                    <img src={"/images/CarBG.png"} alt="" className={`${styles.CardTexture}`} />
                    <img src={item.image_url} className={`${styles.CardIMG}`} alt="" />
                    <p className={`${styles.CarItemTitle}`}>{item.title}</p>
                    <p className={`${styles.CarItemText}`}>{item.type}</p>
                    <p className={`${styles.CarItemText2}`}>{item.transmission}</p>
                    <p className={`${styles.CarItemPrice}`}>{currency}{" "}<span>{!currencyLoading && (item.price * currencyRate).toFixed(2) + "/Day"}</span></p>
                    <button className={`${styles.TrendingBUGreen}`} onClick={goToBookNow.bind(this)}><span>Book Now</span></button>
                  </div>
                </div>);
              })}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
    <div className={`${styles.WhyUsSec}`}>
      <img src="/images/CarBG.png" alt="" className={`${styles.CarBG}`} />
      <div className="container">
        <p className={`${styles.AboutTitle}`}>Why Choose Motu Car Rentals?</p>
        <div className={`${styles.WhyChooseRow}`}>
          <div className={`${styles.WhyChooseDiv}`}>
            <div className={`${styles.WhyChooseUnder}`}>
              <img src="/images/why_choose_us_img1.svg" alt="" />
              <h4 className={`${styles.WhyChooseTitle}`}>Book and Pay Online</h4>
              <p className={`${styles.WhyChooseText}`}>Motu Car Rentals made history as the first car rental company in Samoa to offer a seamless online booking and payment process for rental cars. No need to wait in line or book upon arrival—our website allows customers to book a car, pay online, and receive instant confirmation of their booking itinerary via email.</p>
              <img src="/images/car_steering.svg" className={`${styles.WhyBGGra}`} alt="" />
            </div>
          </div>
          <div className={`${styles.WhyChooseDiv}`}>
            <div className={`${styles.WhyChooseUnder}`}>
              <img src="/images/why_choose_us_img2.svg" alt="" />
              <h4 className={`${styles.WhyChooseTitle}`}>Insurance Coverage</h4>
              <p className={`${styles.WhyChooseText}`}>Every rental car in our fleet is covered by premium insurance, providing coverage for damages starting from WST1000 and above. For more information, email us motucarrentals.com.</p>
              <img src="/images/car_steering.svg" className={`${styles.WhyBGGra}`} alt="" />
            </div>
          </div>
          <div className={`${styles.WhyChooseDiv}`}>
            <div className={`${styles.WhyChooseUnder}`}>
              <img src="/images/why_choose_us_img3.svg" alt="" />
              <h4 className={`${styles.WhyChooseTitle}`}>Customizable Add-Ons</h4>
              <p className={`${styles.WhyChooseText}`}>Enhance your trip with our range of add-ons, available with just a click to make your journey more convenient. Our recommended add-ons include baby car seats, booster car seats, temporary licenses, insurance coverage, and more, such as beach gear and portable gadgets. Choose the options that best suit your needs.</p>
              <img src="/images/car_steering.svg" className={`${styles.WhyBGGra}`} alt="" />
            </div>
          </div>
          <div className={`${styles.WhyChooseDiv}`}>
            <div className={`${styles.WhyChooseUnder}`}>
              <img src="/images/why_choose_us_img4.svg" alt="" />
              <h4 className={`${styles.WhyChooseTitle}`}>24/7 Airport Pick-Up and Drop-Off</h4>
              <p className={`${styles.WhyChooseText}`}>Enjoy hassle-free delivery and return of your rental car at Faleolo International Airport at your selected times.</p>
              <img src="/images/car_steering.svg" className={`${styles.WhyBGGra}`} alt="" />
            </div>
          </div>
          <div className={`${styles.WhyChooseDiv}`}>
            <div className={`${styles.WhyChooseUnder}`}>
              <img src="/images/why_choose_us_img5.svg" alt="" />
              <h4 className={`${styles.WhyChooseTitle}`}>Top-Notch Service</h4>
              <p className={`${styles.WhyChooseText}`}>Backed by countless 5-star reviews on Google and social media from our valuable customers. These glowing testimonials reflect our commitment to exceptional service, reliability, and customer satisfaction.</p>
              <img src="/images/car_steering.svg" className={`${styles.WhyBGGra}`} alt="" />
            </div>
          </div>
          <div className={`${styles.WhyChooseDiv}`}>
            <div className={`${styles.WhyChooseUnder}`}>
              <img src="/images/why_choose_us_img6.svg" alt="" />
              <h4 className={`${styles.WhyChooseTitle}`}>Well-Maintained Rental Cars</h4>
              <p className={`${styles.WhyChooseText}`}>Our fleet is regularly serviced to ensure every vehicle is in great condition for your rental.</p>
              <img src="/images/car_steering.svg" className={`${styles.WhyBGGra}`} alt="" />
            </div>
          </div>
          <div className={`${styles.WhyChooseDiv}`}>
            <div className={`${styles.WhyChooseUnder}`}>
              <img src="/images/why_choose_us_img7.svg" alt="" />
              <h4 className={`${styles.WhyChooseTitle}`}>Fuel-Efficient Rental Cars</h4>
              <p className={`${styles.WhyChooseText}`}>Save on fuel with our energy-efficient cars, designed to reduce costs and minimize environmental impact.</p>
              <img src="/images/car_steering.svg" className={`${styles.WhyBGGra}`} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className={`${styles.InsuranceSec}`}>
      <img src="/images/waveInsur.svg" alt="" className={`${styles.waveInsur}`} />
      <div className="container">
        <div className={`${styles.ProductHead}`}>
          <h3 className={`${styles.InsurTitle}`}>How to Book</h3>
          <p className={`${styles.InsurSmallTitle}`}>Booking a car with us is quick and easy. You can reserve your vehicle <Link to={"/reservation"}> online </Link> or <Link to={"/contact-us"}> contact us </Link> for personalized assistance.</p>
          <br />
          <br />
          <h3 className={`${styles.InsurTitle}`}>Plan Your Samoa Adventure Today</h3>
          <p className={`${styles.InsurSmallTitle}`}>Whether you're exploring the vibrant city of Apia, taking a scenic drive along the coast, or visiting local attractions, Motu Car Rentals is here to make your trip comfortable and stress-free.</p>
        </div>
      </div>
    </div>
  </React.Fragment>);
};

export default withNavigateHook(Cars);