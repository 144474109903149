import React, {useState,useEffect} from 'react';
import styles from './payment-success.module.css';
import {  useParams} from "react-router-dom";
import axios from 'axios';

export default function PaymentSuccess() {
    let { id } = useParams();
    const [emailVal, setEmailVal] = useState('');

    useEffect(()=>{
      axios.get(process.env.REACT_APP_API_URL + 'v1/booking/'+id).then(response => {
        let itemData = response.data.data;
        setEmailVal(itemData?.user_info?.email);
      });
    },[id])

  return (
    <React.Fragment>
      <div className={`${styles.BookingDone}`}>
        <img src="/images/check.png" alt="" />
        <p className={`${styles.BookingTitle}`}>Thank You!</p>
        <p className={`${styles.BookingSmTitle}`}>You successfully created your booking.</p>
        <p className={`${styles.BookingSmTitle}`}>Email: <strong>{emailVal}</strong></p>
        <p className={`${styles.BookingSmTitle}`}>Reference Id: <strong>{id}</strong></p>
      </div>
    </React.Fragment>
  )
}
