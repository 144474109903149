import React,{ useState,useEffect } from 'react';
import styles from './dashboard.module.css';
import { Typography, Breadcrumbs, Select, MenuItem, InputLabel, Box, FormControl } from '@mui/material';
import {Tooltip as Tooltip2} from '@mui/material';
import { Bar } from 'react-chartjs-2'; 
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  Tooltip,
  ArcElement
} from 'chart.js';
import { AlertCircle } from 'react-feather';
import { TableLoader } from  '../../components/LoaderC';
import Loader from  '../../components/Loader';
import {authData} from "../../components/getAuth";
import axios from 'axios';
import moment from 'moment';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  Tooltip,
  ArcElement
);


export default function Home() {
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [allData, setAllData] = useState(null);
  const [currentYear, setCurrentYear] = React.useState( moment().year());

  useEffect(()=>{
    function getData(){
      axios.get(process.env.REACT_APP_API_URL + 'v1/get-dashboard-data', {
        headers: {
          Authorization: `Bearer ${authData()?.api_token}`,
        }
      }).then(response => {
        setContentLoading(false);
        let itemData = response.data.data;
        setAllData(itemData);
        setCurrentYear(moment().year());
      }).catch(error => {
        setContentLoading(false);
      });
    }

    getData();
    
  },[]);


  const handleChange = (event) => {
    setLoading(true);
    setCurrentYear(event.target.value);
    axios.get(process.env.REACT_APP_API_URL + 'v1/get-dashboard-data/'+event.target.value, {
      headers: {
        Authorization: `Bearer ${authData()?.api_token}`,
      }
    }).then(response => {
      setLoading(false);
      let itemData = response.data.data;
      setAllData({...allData,barData:itemData});
    }).catch(error => {
      setLoading(false);
    });
  };

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: false
      },
      legend: {
        display: false,
        position: "bottom"
      },
    },
  };
  
  return (
    <React.Fragment>
      {loading && <Loader /> }
      {contentLoading && <div className="LoaderCard"><div className="Loader TableLoader"><TableLoader /></div></div> }
      {!contentLoading && <div className={`${styles.MainDiv}`}>

        <div className={`${styles.PageTitleWrap}`}>
          <p className={`${styles.PageTitle}`}>Dashboard</p>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="text.primary">Dashboard</Typography>
          </Breadcrumbs>
        </div>

        <div className={`${styles.MainCard}`}>
        
        <div className="row">
            <div className='col-4'>
              <div className={`${styles.Card}`}>
                <div className={`${styles.CardBody}`}>
                  <div>
                    <h2 className={`${styles.CardTitle}`}>Current Month Booking <Tooltip2 title="Total no of current month booking"><AlertCircle/></Tooltip2></h2>
                    <p className={`${styles.CardValue}`}>{allData?.month_data}</p>
                  </div>
                  <div className={`${styles.CardIcon}`}>
                    <img src="/images/booking.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className={`${styles.Card}`}>
                <div className={`${styles.CardBody}`}>
                  <div>
                    <h2 className={`${styles.CardTitle}`}>Upcoming Booking <Tooltip2 title="Total no of upcoming booking"><AlertCircle/></Tooltip2></h2>
                    <p className={`${styles.CardValue}`}>{allData?.upcoming_data}</p>
                  </div>
                  <div className={`${styles.CardIcon}`}>
                    <img src="/images/calendar.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className={`${styles.Card}`}>
                <div className={`${styles.CardBody}`}>
                  <div>
                    <h2 className={`${styles.CardTitle}`}>Current Month Revenue <Tooltip2 title="Total no of current month revenue"><AlertCircle/></Tooltip2></h2>
                    <p className={`${styles.CardValue}`}>WST {allData?.month_revenue}</p>
                  </div>
                  <div className={`${styles.CardIcon}`}>
                    <img src="/images/revenue.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-8'>
              <div className={`${styles.Card}`}>
                <div className={`${styles.CardBody}`}>
                  <div className={`${styles.CardHead}`}>
                    <h2 className={`${styles.CardTitle}`}>Bookings <Tooltip2 title="Total no of current year bookings"><AlertCircle/></Tooltip2></h2>
                    <Box sx={{ minWidth: 150 }} className="CustomSelect">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Year</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={currentYear}
                          label="Year"
                          onChange={handleChange}
                        >
                          {allData?.yearList.map((item,index)=>{
                            return (<MenuItem key={index} value={item}>{item}</MenuItem>);
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                  <Bar options={options} data={allData?.barData} className={`${styles.BarChart}`} />
                </div>
              </div>
            </div>

            <div className='col-4'>
              <div className='col-12'>
                <div className={`${styles.Card}`}>
                  <div className={`${styles.CardBody}`}>
                    <div className={`${styles.CardHead}`}>
                      <h2 className={`${styles.CardTitle}`}>Upcoming bookings <Tooltip2 title="Total upcoming booking list"><AlertCircle/></Tooltip2></h2>
                    </div>
                    <div className={`${styles.UpcomingBookTable}`}>
                      <table>
                        <thead>
                        <tr>
                          <th className={`${styles.TableHead}`}>Customer name</th>
                          <th className={`${styles.TableHead}`}>Car name</th>
                          <th className={`${styles.TableHead}`}>Booking date</th>
                        </tr>
                        </thead>
                        <tbody>
                          {allData?.upcoming_list.map((item,index)=>{
                            return (<tr key={index}>
                              <td className={`${styles.TableBody}`}>{item.first_name} {item.last_name}</td>
                              <td className={`${styles.TableBody}`}>{item.title}</td>
                              <td className={`${styles.TableBody}`}>{moment(item.start_date).format('ddd, MMM D, YYYY')}</td>
                            </tr>);
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12'>
                <div className={`${styles.Card}`}>
                  <div className={`${styles.CardBody}`}>
                    <div className={`${styles.CardHead}`}>
                      <h2 className={`${styles.CardTitle}`}>Total bookings of all cars <Tooltip2 title="Total no of all booking cars"><AlertCircle/></Tooltip2></h2>
                    </div>
                    <Doughnut options={options} data={allData?.doughnutData} className={`${styles.DoughnutChart}`} />
                  </div>
                </div>
              </div>
            </div>

          </div>          

        </div>

    </div>}
    </React.Fragment>

  );
}