import React, {useState,useEffect} from 'react';
import styles from './add.module.css';
import { Typography, Breadcrumbs,Button  } from '@mui/material';
import {  useNavigate,Link ,useParams} from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import Select from "react-select";
import { NumericFormat } from 'react-number-format';
import {  Upload, X,  } from 'react-feather';
import { toast } from 'react-smart-toaster';
import { FromLoader } from  '../../components/LoaderC';
import Loader from  '../../components/Loader';
import withAdminAuth from "../../components/withAdminAuth";
import {authData} from "../../components/getAuth";
import { Editor } from '@tinymce/tinymce-react';

const carTypeOptions = [
  { value: 'Compact', label: 'Compact' },
  { value: 'Mini Van', label: 'Mini Van' },
  { value: 'SUV', label: 'SUV' },
]
const airConditioneOptions = [
  { value: 1, label: 'Yes' },
  { value: 0, label: 'No' },
]

function CarrAdd({ history, match }) {
  const auth = authData();
  const audience = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  let { id } = useParams();
  const isAddMode = !id;
  const [ImgClass, setImgClass] = useState({ progressBar: 'hide', progress: 0, imageBlock: 'hide', imagePathArr: '', imageNameArr: '' });
  const [ImgPathTemp, setImgPathTemp] = useState({ Files: '', imagePathArr: '' });
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [editorLicenseVal, setEditorLicenseVal] = useState('');
  const [editorSecurityVal, setEditorSecurityVal] = useState('');
  const [editorInsuranceVal, setEditorInsuranceVal] = useState('');
  const [editorFuelVal, setEditorFuelVal] = useState('');
  const [editorMileageVal, setEditorMileageVal] = useState('');
  const [editorCleaningVal, setEditorCleaningVal] = useState('');
  const schema = yup.object().shape({
    title: yup.string().required('This field is Required').max(50, 'Name cannot be more than 50 characters'),
    type: yup.object().required('This field is Required'),
    price: yup.string().required('This field is Required'),
    quantity: yup.string().required('This field is Required'),
    security_amount: yup.string().required('This field is Required'),
    doors: yup.string().required('This field is Required'),
    passengers: yup.string().required('This field is Required'),
    luggage: yup.string().required('This field is Required'),
    transmission: yup.string().required('This field is Required'),
    air_conditioning: yup.object().required('This field is Required'),
    minimum_age: yup.string().required('This field is Required'),
    drive_licence_req: yup.string().nullable(),
    security_policy: yup.string().nullable(),
    insurance_coverage: yup.string().nullable(),
    fuel_policy: yup.string().nullable(),
    mileage: yup.string().nullable(),
    cleaning_policy: yup.string().nullable(),
    imagefile: yup.mixed().test("required", "Please upload a file", (file) => {
      if (ImgClass?.imagePathArr !== '') return true;
      if (ImgPathTemp?.imagePathArr !== '') return true;
      return false;
    })
  });
  
  const { register, handleSubmit, control,watch,setValue,  formState: { errors } } = useForm({
    resolver: yupResolver(schema), mode: 'onChange'
  });

  useEffect(()=>{
    if(!isAddMode){
      if(contentLoading){
        axios.get(audience + 'v1/cars/'+id, {
          headers: {
            Authorization: `Bearer ${auth?.api_token}`,
          }
        }).then(response => {
          setContentLoading(false);
          let itemData = response.data.data;
          setValue('title',itemData.title);
          setValue('type',{ value: itemData.type, label: itemData.type });
          setValue('price',itemData.price);
          setValue('quantity',itemData.quantity);
          setValue('security_amount',itemData.security_amount);
          setValue('doors',itemData.doors);
          setValue('passengers',itemData.passengers);
          setValue('luggage',itemData.luggage);
          setValue('transmission',itemData.transmission);
          setValue('minimum_age',itemData.minimum_age);

          setEditorLicenseVal(itemData.drive_licence_req);
          setValue('drive_licence_req',itemData.drive_licence_req);

          setEditorSecurityVal(itemData.security_policy);
          setValue('security_policy',itemData.security_policy);

          setEditorInsuranceVal(itemData.insurance_coverage);
          setValue('insurance_coverage',itemData.insurance_coverage);

          setEditorFuelVal(itemData.fuel_policy);
          setValue('fuel_policy',itemData.fuel_policy);

          setEditorMileageVal(itemData.mileage);
          setValue('mileage',itemData.mileage);

          setEditorCleaningVal(itemData.cleaning_policy);
          setValue('cleaning_policy',itemData.cleaning_policy);

          if(itemData.air_conditioning === 1 || itemData.air_conditioning === "1")
            setValue('air_conditioning',{ value: 1, label: 'Yes' });
          else
            setValue('air_conditioning',{ value: 0, label: 'No' });
  
          setImgClass({ ...ImgClass, imagePathArr: itemData.image_url});
        }).catch(error => {
          setContentLoading(false);
        });
      }
    }else{
      setContentLoading(false);
    }
  },[isAddMode,audience,id,setValue,ImgClass,contentLoading,auth]);
  
  const AirConditionField = React.forwardRef((field, ref) => {
    return (<Controller
      control={control}
      options={airConditioneOptions}
      {...register('air_conditioning')}
      render={({ field }) => (
        <Select {...field} options={airConditioneOptions}  />
      )}
    />)
  });
  
  const CarTypeField = React.forwardRef((field, ref) => {
    return (<Controller
      control={control}
      options={carTypeOptions}
      {...register('type')}
      render={({ field }) => (
        <Select {...field} options={carTypeOptions}  />
      )}
    />)
  })
  
  /****Image Upload****/
  const fileHandler = (e) => {
    var validExtensions = ['jpg', 'jpeg', 'png']; //array of valid extensions
    var fileName = e.target.files[0].name;
    var fileNameExt = fileName.substr(fileName.lastIndexOf('.') + 1);
    if (validExtensions.indexOf(fileNameExt.toLowerCase()) === -1) {
      toast.error('File type is not allowed');

    }
    else if (e.target.files[0].size > 5242880) {
      toast.error('Image size should not be greater than 5 MB.');
    }
    else {
      let tempFiles = ImgPathTemp.Files;
      let tempImages = ImgPathTemp.imagePathArr;
      let file = e.target.files[0];
      tempFiles = file;
      tempImages = URL.createObjectURL(file);
      setImgPathTemp({ imagePathArr: tempImages, Files: tempFiles });

      deleteImage();
    }
  }

  function deleteTempImage() {
    setValue('imagefile', null);
    setImgPathTemp({ imagePathArr: '', Files: "" });
  }

  function deleteImage() {
    setImgClass({ ...ImgClass, imagePathArr: '', imageNameArr: '' });
  }

  function fileUploadButtonClickTemp() {
    document.getElementById('fileUploadID').getElementsByClassName('inputBtn')[0].click();
    return false;
  }
  
  async function onSubmit(data) {
    if (data.type) {
      data.type = data.type.value;
    }
    if (data.air_conditioning) {
      data.air_conditioning = data.air_conditioning.value;
    }
    
    let formData = new FormData();
    Object.keys(data).map((key) => {
      if (key !== 'imagefile') {
        formData.append(key, data[key]);
      }
      return true;
    });
    
    if (ImgPathTemp.Files) {
      formData.append("image", ImgPathTemp.Files);
    }
    
    const postData = formData;
    setLoading(true);

    if(isAddMode){
      return axios.post(audience + 'v1/cars', postData, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then(response => {
        setLoading(false);
        toast.success(response.data.message);
        navigate('/admin/car/list');
      }).catch(error => {
        setLoading(false);
      });
    }else{
      return axios.post(audience + 'v1/cars/'+id, postData, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then(response => {
        setLoading(false);
        toast.success(response.data.message);
        navigate('/admin/car/list');
      }).catch(error => {
        setLoading(false);
      });
    }
  }


  const handleEditorChangeLicense = (content, editor) => {
    setEditorLicenseVal(content);
    setValue('drive_licence_req', content);
  }
  const handleEditorChangeSecurity = (content, editor) => {
    setEditorSecurityVal(content);
    setValue('security_policy', content);
  }
  const handleEditorChangeInsurance = (content, editor) => {
    setEditorInsuranceVal(content);
    setValue('insurance_coverage', content);
  }
  const handleEditorChangeFuel = (content, editor) => {
    setEditorFuelVal(content);
    setValue('fuel_policy', content);
  }
  const handleEditorChangeMileage = (content, editor) => {
    setEditorMileageVal(content);
    setValue('mileage', content);
  }
  const handleEditorChangeCleaning = (content, editor) => {
    setEditorCleaningVal(content);
    setValue('cleaning_policy', content);
  }
  
  return (
      <React.Fragment>
        {loading && <Loader /> }
        {contentLoading && <div className="LoaderCard"><div className="Loader FromLoader"><FromLoader /></div></div> }
        {!contentLoading && <div className={`${styles.MainDiv}`}>
          <div className={`${styles.PageTitleWrap}`}>
            <p className={`${styles.PageTitle}`}>Car {isAddMode?'Add':'Edit'}</p>
            <Breadcrumbs aria-label="breadcrumb" className='breadcrumb'>
              <Link underline="hover" color="inherit" to="/admin/dashboard">Dashboard</Link>
              <Link underline="hover" color="inherit" to={'/admin/car/list'}>Cars</Link>
              <Typography color="text.primary">{isAddMode?'Add':'Edit'}</Typography>
            </Breadcrumbs>
          </div>
          
          <div className={`${styles.MainCard}`}>
            <div className={`${styles.AddUserMain}`}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>Title*</label>
                    <input {...register("title")} className={`${styles.FormControl}`} />
                    {errors.title && !watch().title && <span className={`${styles.ErrorM}`}>{errors.title.message}</span>}
                  </div>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>Car Type*</label>
                    <CarTypeField />
                    {errors.type && !watch().type && <span className={`${styles.ErrorM}`}>{errors.type.message}</span>}
                  </div>
                </div>

              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormGroup}`}>
                  <label className={`${styles.FormLabel}`}>Price(WST)*</label>
                  <Controller
                    control={control}
                    name="price"
                    className={`${styles.FormControl}`}
                    render={({ field: { onChange, name, value } }) => (
                      <NumericFormat
                        decimalScale={2} 
                        name={name}
                        value={value}
                        onChange={onChange}
                        className={`${styles.FormControl}`}
                      />
                    )}
                  />
                  {errors.price && <span className={`${styles.ErrorM}`}>{errors.price.message}</span>} 
                </div>
                <div className={`${styles.FormGroup}`}>
                  <label className={`${styles.FormLabel}`}>Security Amount*</label>
                  <Controller
                    control={control}
                    name="security_amount"
                    className={`${styles.FormControl}`}
                    render={({ field: { onChange, name, value } }) => (
                      <NumericFormat
                        decimalScale={2} 
                        name={name}
                        value={value}
                        onChange={onChange}
                        className={`${styles.FormControl}`}
                      />
                    )}
                  />

                  {errors.doors && <span className={`${styles.ErrorM}`}>{errors.doors.message}</span>} 
                </div>
              </div>

              <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup}`}>
                  <label className={`${styles.FormLabel}`}>Doors*</label>
                  <Controller
                    control={control}
                    name="doors"
                    className={`${styles.FormControl}`}
                    render={({ field: { onChange, name, value } }) => (
                      <NumericFormat
                        isAllowed={(values, sourceInfo) => {
                          const { value } = values;
                          return value < 100;
                        }}
                        name={name}
                        value={value}
                        onChange={onChange}
                        className={`${styles.FormControl}`}
                      />
                    )}
                  />

                  {errors.doors && <span className={`${styles.ErrorM}`}>{errors.doors.message}</span>} 
                </div>

                <div className={`${styles.FormGroup}`}>
                  <label className={`${styles.FormLabel}`}>Passengers*</label>
                  <Controller
                    control={control}
                    name="passengers"
                    className={`${styles.FormControl}`}
                    render={({ field: { onChange, name, value } }) => (
                      <NumericFormat
                        isAllowed={(values, sourceInfo) => {
                          const { value } = values;
                          return value < 100;
                        }}
                        name={name}
                        value={value}
                        onChange={onChange}
                        className={`${styles.FormControl}`}
                      />
                    )}
                  />
                  {errors.passengers && <span className={`${styles.ErrorM}`}>{errors.passengers.message}</span>} 
                </div>

                </div> 
                <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormGroup}`}>
                  <label className={`${styles.FormLabel}`}>Luggage*</label>
                  <input type="text" {...register("luggage")} className={`${styles.FormControl}`} />
                  {errors.luggage && <span className={`${styles.ErrorM}`}>{errors.luggage.message}</span>} 
                </div>
                <div className={`${styles.FormGroup}`}>
                  <label className={`${styles.FormLabel}`}>Transmission*</label>
                  <input type="text" {...register("transmission")} className={`${styles.FormControl}`} />
                
                  {errors.transmission && <span className={`${styles.ErrorM}`}>{errors.transmission.message}</span>} 
                </div>

              </div>
              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormGroup}`}>
                  <label className={`${styles.FormLabel}`}>Air Conditioning*</label>
                  <AirConditionField />
                  {errors.air_conditioning && <span className={`${styles.ErrorM}`}>{errors.air_conditioning.message}</span>} 
                </div>

                <div className={`${styles.FormGroup}`}>
                  <label className={`${styles.FormLabel}`}>Minimum Age*</label>
                  <Controller
                    control={control}
                    name="minimum_age"
                    className={`${styles.FormControl}`}
                    render={({ field: { onChange, name, value } }) => (
                      <NumericFormat
                        isAllowed={(values, sourceInfo) => {
                          const { value } = values;
                          return value < 100;
                        }}
                        name={name}
                        value={value}
                        onChange={onChange}
                        className={`${styles.FormControl}`}
                      />
                    )}
                  />

                  {errors.minimum_age && <span className={`${styles.ErrorM}`}>{errors.minimum_age.message}</span>} 
                </div>
              </div>
              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormGroup}`}>
                  <label className={`${styles.FormLabel}`}>Quantity*</label>
                  <Controller
                    control={control}
                    name="quantity"
                    className={`${styles.FormControl}`}
                    render={({ field: { onChange, name, value } }) => (
                      <NumericFormat
                        isAllowed={(values, sourceInfo) => {
                          const { value } = values;
                          return value < 1000;
                        }}
                        name={name}
                        value={value}
                        onChange={onChange}
                        className={`${styles.FormControl}`}
                      />
                    )}
                  />

                  {errors.quantity && <span className={`${styles.ErrorM}`}>{errors.quantity.message}</span>} 
                </div>
              </div>






              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormGroupFull}`}>
                  <label className={`${styles.FormLabel}`}>Drive & License Requirements</label>
                  {/* <textarea {...register("drive_licence_req")} className={`${styles.FormControlTextArea}`} rows="5"></textarea> */}

                  <input type="hidden" {...register("drive_licence_req")} className={`${styles.FormControl}`} />
                  <Editor onEditorChange={handleEditorChangeLicense} value={editorLicenseVal}
                    apiKey='esnq02ea10s4qrcjx6m5z9h03c3iln9jfml86ysvw8legkpo'
                    init={{
                      height: 400,
                      menubar: false,
                      plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                      toolbar:
                        'undo redo | bold italic underline strikethrough | fontselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat |  fullscreen  | image media link | ltr rtl | code'
                    }}
                  />
                  {errors.drive_licence_req && !watch().drive_licence_req && <span className={`${styles.ErrorM}`}>{errors.drive_licence_req.message}</span>}
                </div>
              </div>
              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormGroupFull}`}>
                  <label className={`${styles.FormLabel}`}>Security Policy</label>
                  {/* <textarea {...register("security_policy")} className={`${styles.FormControlTextArea}`} rows="5"></textarea> */}
                  <input type="hidden" {...register("security_policy")} className={`${styles.FormControl}`} />
                  <Editor onEditorChange={handleEditorChangeSecurity} value={editorSecurityVal}
                    apiKey='esnq02ea10s4qrcjx6m5z9h03c3iln9jfml86ysvw8legkpo'
                    init={{
                      height: 400,
                      menubar: false,
                      plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                      toolbar:
                        'undo redo | bold italic underline strikethrough | fontselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat |  fullscreen  | image media link | ltr rtl | code'
                    }}
                  />
                  
                  {errors.security_policy && !watch().security_policy && <span className={`${styles.ErrorM}`}>{errors.security_policy.message}</span>}
                </div>
              </div>
              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormGroupFull}`}>
                  <label className={`${styles.FormLabel}`}>Insurance Coverage</label>
                  {/* <textarea {...register("insurance_coverage")} className={`${styles.FormControlTextArea}`} rows="5"></textarea> */}
                  <input type="hidden" {...register("insurance_coverage")} className={`${styles.FormControl}`} />
                  <Editor onEditorChange={handleEditorChangeInsurance} value={editorInsuranceVal}
                    apiKey='esnq02ea10s4qrcjx6m5z9h03c3iln9jfml86ysvw8legkpo'
                    init={{
                      height: 400,
                      menubar: false,
                      plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                      toolbar:
                        'undo redo | bold italic underline strikethrough | fontselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat |  fullscreen  | image media link | ltr rtl | code'
                    }}
                  />
                  {errors.insurance_coverage && !watch().insurance_coverage && <span className={`${styles.ErrorM}`}>{errors.insurance_coverage.message}</span>}
                </div>
              </div>
              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormGroupFull}`}>
                  <label className={`${styles.FormLabel}`}>Fuel Policy</label>
                  {/* <textarea {...register("fuel_policy")} className={`${styles.FormControlTextArea}`} rows="5"></textarea> */}

                  <input type="hidden" {...register("fuel_policy")} className={`${styles.FormControl}`} />
                  <Editor onEditorChange={handleEditorChangeFuel} value={editorFuelVal}
                    apiKey='esnq02ea10s4qrcjx6m5z9h03c3iln9jfml86ysvw8legkpo'
                    init={{
                      height: 400,
                      menubar: false,
                      plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                      toolbar:
                        'undo redo | bold italic underline strikethrough | fontselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat |  fullscreen  | image media link | ltr rtl | code'
                    }}
                  />
                  {errors.fuel_policy && !watch().fuel_policy && <span className={`${styles.ErrorM}`}>{errors.fuel_policy.message}</span>}
                </div>
              </div>
              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormGroupFull}`}>
                  <label className={`${styles.FormLabel}`}>Mileage</label>
                  {/* <textarea {...register("mileage")} className={`${styles.FormControlTextArea}`} rows="5"></textarea> */}
                  <input type="hidden" {...register("mileage")} className={`${styles.FormControl}`} />
                  <Editor onEditorChange={handleEditorChangeMileage} value={editorMileageVal}
                    apiKey='esnq02ea10s4qrcjx6m5z9h03c3iln9jfml86ysvw8legkpo'
                    init={{
                      height: 400,
                      menubar: false,
                      plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                      toolbar:
                        'undo redo | bold italic underline strikethrough | fontselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat |  fullscreen  | image media link | ltr rtl | code'
                    }}
                  />
                  {errors.mileage && !watch().mileage && <span className={`${styles.ErrorM}`}>{errors.mileage.message}</span>}
                </div>
              </div>

              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormGroupFull}`}>
                  <label className={`${styles.FormLabel}`}>Cleaning Policy</label>
                  <input type="hidden" {...register("cleaning_policy")} className={`${styles.FormControl}`} />
                  <Editor onEditorChange={handleEditorChangeCleaning} value={editorCleaningVal}
                    apiKey='esnq02ea10s4qrcjx6m5z9h03c3iln9jfml86ysvw8legkpo'
                    init={{
                      height: 400,
                      menubar: false,
                      plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                      toolbar:
                        'undo redo | bold italic underline strikethrough | fontselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat |  fullscreen  | image media link | ltr rtl | code'
                    }}
                  />
                  {errors.cleaning_policy && !watch().cleaning_policy && <span className={`${styles.ErrorM}`}>{errors.cleaning_policy.message}</span>}
                </div>
              </div>

              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormGroupFull}`}>
                  <div className={`${styles.FileIMGDiv}`}>
                    <div className={`${styles.FileUpDuBUDiv}`}>
                      <label htmlFor="file-upload" className={`${styles.UploadLabel}`} id="fileUploadID">
                        <input type="file" className="inputBtn"  {...register('imagefile')} onChange={fileHandler} accept=".jpg,.png,.jpeg" inputprops={{ accept: 'image/*' }} />
                        <span onClick={fileUploadButtonClickTemp.bind()}>
                          <div className={`${styles.UpIcon}`}><Upload /></div> <p>Upload Photo</p>
                        </span>
                        <p className={`${styles.UploadText}`}>File size upto 100 KB</p>
                        <p className={`${styles.UploadText2}`}>JPEG, JPG, PNG</p>
                      </label>
                      
                      <span className={'e-upload-progress-wrap ' + ImgClass.progressBar}>
                        <span className="e-progress-inner-wrap"></span>
                        <span className="e-progress-bar-text">{ImgClass.progress}%</span>
                      </span>
                    </div>
                    {ImgClass.imagePathArr && <div className={`${styles.ImgFile}`}>
                      <img src={ImgClass.imagePathArr} alt="" />
                      <Button onClick={deleteImage.bind(this)}><X /></Button>
                    </div>}
                    {ImgPathTemp.imagePathArr && ImgPathTemp.imagePathArr !== '' && <div className={`${styles.ImgFile}`}>
                      <img src={ImgPathTemp.imagePathArr} alt="" />
                      <Button onClick={deleteTempImage.bind(this)}><X /></Button>
                    </div>}
                  </div>
                  {errors.imagefile && <p className={`${styles.ErrorM}`}>{errors.imagefile.message}</p>}
                </div>
              </div>
             
                             
              <div className={`${styles.FormButtonArea}`}>
                {/* <button className={`${styles.CancelBU}`} type="button">Cancel</button> */}
                <Link to={`/admin/car/list`} as={Button} className={`${styles.CancelBU}`}>Cancel</Link>
                <input className={`${styles.SubmitBU}`} value="Submit" type="submit" />
              </div>
            </form>
          </div>

        </div>
        </div>}
      </React.Fragment>
  )}


export default withAdminAuth(CarrAdd);