import React from 'react';
import styles from './blog.module.css';
import { Parallax } from 'react-parallax';
import Fade from 'react-reveal/Fade';
//import { Link } from 'react-router-dom';
import { ArrowRight } from 'react-feather';
//import Carousel from "react-multi-carousel";
import withNavigateHook from '../../../customer/components/withNavigateHook';
import axios from 'axios';

  // const responsive = {
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items: 3,
  //     slidesToSlide: 1 // optional, default to 1.
  //   },
  //   tablet: {
  //     breakpoint: { max: 1024, min: 464 },
  //     items: 2,
  //     slidesToSlide: 1 // optional, default to 1.
  //   },
  //   tablet2: {
  //     breakpoint: { max: 768, min: 300 },
  //     items: 1,
  //     slidesToSlide: 1 // optional, default to 1.
  //   },
  //   mobile: {
  //     breakpoint: { max: 464, min: 0 },
  //     items: 1,
  //     slidesToSlide: 1 // optional, default to 1.
  //   }
  // }



  class Blog extends React.Component {
    constructor(props){
      super(props);
      this.state={
        blogList:[]
      };
      this.props=props;
    }
    componentWillMount(){
      axios(process.env.REACT_APP_API_URL + 'v1/blog-list').then(response => {
        let allData = response.data.data;
        this.setState({...this.state, blogList: allData});
      }).catch(error=>{
        this.setState({...this.state, blogList: []});
      });

    }


    render() {
      return (
      <React.Fragment>

        <div className="InnerBanner">
          <Parallax bgImage="/images/blog-banner.jpg" strength={400}>
            <div style={{ height: 450 }} className={`${styles.BannerConArea}`}>
              <div className='container'>
                <div className={`${styles.InnerBannerRow}`}>
                  <h2 className={`${styles.InnerBannerTitle}`}>Talofa lava.<br/> Welcome to Quinney’s Blog!</h2>
                </div>
              </div>
            </div>
          </Parallax>
        </div>

        <div className={`${styles.ServiceSec}`}>
          <img src="/images/CarBG.png" alt="" className={`${styles.CarBG}`} />
          <Fade bottom>
          <div className='container'>
            <div className={`${styles.ProductHead}`}>
              <h3 class={`${styles.ServiceTitle}`}>Our Blogs</h3>
              <p class={`${styles.ProductSmallTitle}`}>I appreciate you visiting. I'm an experienced blogger and I love writing. My writings reflect my Samoan culture, areas of expertise and interests.I write to inform, amuse, inspire, and educate.</p>
            </div>
            <div className={`${styles.ServicesRow}`}>
            {this.state?.blogList?.map((item, index) => {
            return (
              <div className={`${styles.ServicesCard}`}>
                <img src={item.image_url} alt="" />
                <div className={`${styles.BlogBody}`}>
                <p className={`${styles.ServiceCardTitle}`}>{item.title}</p>
                <p className={`${styles.ServiceText}`}>{item.description}</p>
              
                <a href={item.link}  rel="noreferrer" target="_blank" className={`${styles.BlogDeBU}`}>Learn More <ArrowRight/></a>
                </div>
              </div>
              )
                    })}

              {/* <div className={`${styles.ServicesCard}`}>
                <img src="/images/blogImg2.jpg" alt="" />
                <div className={`${styles.BlogBody}`}>
                <p className={`${styles.ServiceCardTitle}`}>Lorem ipsum dolor sit amet</p>
                <p className={`${styles.ServiceText}`}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do diam eiusmod tempor magna aliqua sed ut enim ad minim veniam, quis nostrud.</p>
                <Link className={`${styles.BlogDeBU}`}>Learn More <ArrowRight/></Link>
                </div>
              </div>

              <div className={`${styles.ServicesCard}`}>
                <img src="/images/blogImg3.jpg" alt="" />
                <div className={`${styles.BlogBody}`}>
                <p className={`${styles.ServiceCardTitle}`}>Lorem ipsum dolor sit ametonsectetur adipiscing</p>
                <p className={`${styles.ServiceText}`}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do diam eiusmod tempor magna aliqua sed ut enim ad minim veniam, quis nostrud.</p>
                <Link className={`${styles.BlogDeBU}`}>Learn More <ArrowRight/></Link>
                </div>
              </div>

              <div className={`${styles.ServicesCard}`}>
                <img src="/images/blogImg4.jpg" alt="" />
                <div className={`${styles.BlogBody}`}>
                <p className={`${styles.ServiceCardTitle}`}>Lorem ipsum dolor sit amet</p>
                <p className={`${styles.ServiceText}`}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do diam eiusmod tempor magna aliqua sed ut enim ad minim veniam, quis nostrud.</p>
                <Link className={`${styles.BlogDeBU}`}>Learn More <ArrowRight/></Link>
                </div>
              </div>

              <div className={`${styles.ServicesCard}`}>
                <img src="/images/blogImg5.jpg" alt="" />
                <div className={`${styles.BlogBody}`}>
                <p className={`${styles.ServiceCardTitle}`}>Lorem ipsum dolor sit amet</p>
                <p className={`${styles.ServiceText}`}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do diam eiusmod tempor magna aliqua sed ut enim ad minim veniam, quis nostrud.</p>
                <Link className={`${styles.BlogDeBU}`}>Learn More <ArrowRight/></Link>
                </div>
              </div>

              <div className={`${styles.ServicesCard}`}>
                <img src="/images/blogImg6.jpg" alt="" />
                <div className={`${styles.BlogBody}`}>
                <p className={`${styles.ServiceCardTitle}`}>Lorem ipsum dolor sit amet onsectetur adipiscing</p>
                <p className={`${styles.ServiceText}`}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do diam eiusmod tempor magna aliqua sed ut enim ad minim veniam, quis nostrud.</p>
                <Link className={`${styles.BlogDeBU}`}>Learn More <ArrowRight/></Link>
                </div>
              </div> */}

            </div>
          </div>
          </Fade>          
        </div>

        {/* <div className={`${styles.ProductSec}`}>
          <div className='container'>
            
              <Fade bottom>
                <div className={`${styles.BodyMRow}`}>
                  <h3 class={`${styles.ProductTitle}`}>Most Recent Posts</h3>
                  <div className={`${styles.ProductSlider} ProductSlider`}>
                    <Carousel
                    swipeable={true}
                    arrows={true}
                    draggable={true}
                    showDots={false}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    // autoPlay={props.deviceType !== "mobile" ? true : false}
                    autoPlay={false}
                    autoPlaySpeed={4000}
                    keyBoardControl={true}
                    customTransition="2000ms all"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    // removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                    deviceType={this.props.deviceType}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px">
                      <div className={`${styles.Item}`}>
                        <div className={`${styles.ServicesCardUnder}`}>
                          <img src="/images/blogImg1.jpg" alt="" />
                          <div className={`${styles.BlogBody}`}>
                          <p className={`${styles.ServiceCardTitle}`}>Lorem ipsum dolor sit amet onsectetur adipiscing</p>
                          <p className={`${styles.ServiceText}`}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do diam eiusmod tempor magna aliqua sed ut enim ad minim veniam, quis nostrud.</p>
                          <Link className={`${styles.BlogDeBU}`}>Learn More <ArrowRight/></Link>
                          </div>
                        </div>
                      </div>
                      <div className={`${styles.Item}`}>
                        <div className={`${styles.ServicesCardUnder}`}>
                          <img src="/images/blogImg2.jpg" alt="" />
                          <div className={`${styles.BlogBody}`}>
                          <p className={`${styles.ServiceCardTitle}`}>Lorem ipsum dolor sit amet onsectetur adipiscing</p>
                          <p className={`${styles.ServiceText}`}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do diam eiusmod tempor magna aliqua sed ut enim ad minim veniam, quis nostrud.</p>
                          <Link className={`${styles.BlogDeBU}`}>Learn More <ArrowRight/></Link>
                          </div>
                        </div>
                      </div>
                      <div className={`${styles.Item}`}>
                        <div className={`${styles.ServicesCardUnder}`}>
                          <img src="/images/blogImg3.jpg" alt="" />
                          <div className={`${styles.BlogBody}`}>
                          <p className={`${styles.ServiceCardTitle}`}>Lorem ipsum dolor sit amet onsectetur adipiscing</p>
                          <p className={`${styles.ServiceText}`}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do diam eiusmod tempor magna aliqua sed ut enim ad minim veniam, quis nostrud.</p>
                          <Link className={`${styles.BlogDeBU}`}>Learn More <ArrowRight/></Link>
                          </div>
                        </div>
                      </div>
                      <div className={`${styles.Item}`}>
                        <div className={`${styles.ServicesCardUnder}`}>
                          <img src="/images/blogImg4.jpg" alt="" />
                          <div className={`${styles.BlogBody}`}>
                          <p className={`${styles.ServiceCardTitle}`}>Lorem ipsum dolor sit amet onsectetur adipiscing</p>
                          <p className={`${styles.ServiceText}`}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do diam eiusmod tempor magna aliqua sed ut enim ad minim veniam, quis nostrud.</p>
                          <Link className={`${styles.BlogDeBU}`}>Learn More <ArrowRight/></Link>
                          </div>
                        </div>
                      </div>
                      <div className={`${styles.Item}`}>
                        <div className={`${styles.ServicesCardUnder}`}>
                          <img src="/images/blogImg5.jpg" alt="" />
                          <div className={`${styles.BlogBody}`}>
                          <p className={`${styles.ServiceCardTitle}`}>Lorem ipsum dolor sit amet onsectetur adipiscing</p>
                          <p className={`${styles.ServiceText}`}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do diam eiusmod tempor magna aliqua sed ut enim ad minim veniam, quis nostrud.</p>
                          <Link className={`${styles.BlogDeBU}`}>Learn More <ArrowRight/></Link>
                          </div>
                        </div>
                      </div>
                    </Carousel>
                  </div>
                </div>
              </Fade>
            
          </div>
        </div> */}

      </React.Fragment>
        )}
      }
      
export default withNavigateHook(Blog);