import React, {useState,useEffect} from 'react';
import styles from './add.module.css';
import { Typography, Breadcrumbs,Button  } from '@mui/material';
import {  useNavigate,Link ,useParams} from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import { NumericFormat } from 'react-number-format';
import { toast } from 'react-smart-toaster';
import { FromLoader } from  '../../components/LoaderC';
import Loader from  '../../components/Loader';
import withAdminAuth from "../../components/withAdminAuth";
import {authData} from "../../components/getAuth";
import Select from "react-select";


function TestimonialAdd({ history, match }) {
  const auth = authData();
  const APIToken = auth?.api_token;
  const navigate = useNavigate();
  let { id } = useParams();
  const isAddMode = !id;
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [carList, setCarList] = useState([]);

  const schema = yup.object().shape({
    car_id: yup.object().required('This field is Required'),
    rating: yup.string().required('This field is Required'),
    name: yup.string().required('This field is Required'),
    comments: yup.string().required('This field is Required')
  });
  
  const { register, handleSubmit, control,watch,setValue,  formState: { errors } } = useForm({
    resolver: yupResolver(schema), mode: 'onChange'
  });

  useEffect(()=>{
    axios.get(process.env.REACT_APP_API_URL + 'v1/car-list').then(response => {
      let carList = response.data.data.map(i=>{
        return {value:i.id,label:i.title}; 
      });
      setCarList(carList);
    });
  },[]);

  useEffect(()=>{
    if(!isAddMode){
      if(contentLoading){
        axios.get(process.env.REACT_APP_API_URL + 'v1/testimonials/'+id, {
          headers: {
            Authorization: `Bearer ${APIToken}`,
          }
        }).then(response => {
          setContentLoading(false);
          let itemData = response.data.data;

          setValue('comments',itemData.comments);
          setValue('name',itemData.name);
          setValue('rating',itemData.rating);
          if(itemData.car !== null){
            setValue('car_id',{value: itemData.car?.id, label: itemData.car?.title});
          }
        }).catch(error => {
          setContentLoading(false);
        });
      }
    }else{
      setContentLoading(false);
    }
  },[isAddMode,id,setValue,contentLoading,APIToken]);

  async function onSubmit(data) {
    data = {...data,car_id: data?.car_id?.value};
    setLoading(true);

    if(isAddMode){
      return axios.post(process.env.REACT_APP_API_URL + 'v1/testimonials', data, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then(response => {
        setLoading(false);
        toast.success(response.data.message);
        navigate('/admin/testimonial/list');
      }).catch(error => {
        setLoading(false);
      });
    }else{
      return axios.put(process.env.REACT_APP_API_URL + 'v1/testimonials/'+id, data, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then(response => {
        setLoading(false);
        toast.success(response.data.message);
        navigate('/admin/testimonial/list');
      }).catch(error => {
        setLoading(false);
      });
    }
  }

  return (
      <React.Fragment>
        {loading && <Loader /> }
        {contentLoading && <div className="LoaderCard"><div className="Loader FromLoader"><FromLoader /></div></div> }
        {!contentLoading && <div className={`${styles.MainDiv}`}>
          <div className={`${styles.PageTitleWrap}`}>
            <p className={`${styles.PageTitle}`}>Testimonial {isAddMode?'Add':'Edit'}</p>
            <Breadcrumbs aria-label="breadcrumb" className='breadcrumb'>
              <Link underline="hover" color="inherit" to="/admin/dashboard">Dashboard</Link>
              <Link underline="hover" color="inherit" to={'/admin/testimonial/list'}>Testimonial</Link>
              <Typography color="text.primary">{isAddMode?'Add':'Edit'}</Typography>
            </Breadcrumbs>
          </div>
          
          <div className={`${styles.MainCard}`}>
            <div className={`${styles.AddUserMain}`}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>Car*</label>
                    <Controller
                      control={control}
                      options={carList}
                      {...register('car_id')}
                      render={({ field }) => (
                        <Select {...field} options={carList}  />
                      )}
                    />
                    {errors.car_id && !watch().car_id && <span className={`${styles.ErrorM}`}>{errors.car_id.message}</span>}
                  </div>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>Ratings*</label>
                    <Controller
                      control={control}
                      name="rating"
                      className={`${styles.FormControl}`}
                      render={({ field: { onChange, name, value } }) => (
                        <NumericFormat
                          decimalScale={0} 
                          name={name}
                          value={value}
                          onChange={onChange}
                          className={`${styles.FormControl}`}
                          isAllowed={(values) => {
                            const {floatValue} = values;
                            return floatValue <= 5;
                          }}
                        />
                      )}
                    />
                    {errors.rating && !watch().rating && <span className={`${styles.ErrorM}`}>{errors.rating.message}</span>}
                  </div>
                </div>

              <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>Customer Name*</label>
                    <input {...register("name")} className={`${styles.FormControl}`} />
                    {errors.name && !watch().name && <span className={`${styles.ErrorM}`}>{errors.name.message}</span>}
                  </div>
                </div>

              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormGroupFull}`}>
                  <label className={`${styles.FormLabel}`}>Comments*</label>
                  <textarea {...register("comments")} className={`${styles.FormControlTextArea}`} rows={3}></textarea>
                  {errors.comments && !watch().comments && <span className={`${styles.ErrorM}`}>{errors.comments.message}</span>}
                </div>
              </div>
              
             
                             
              <div className={`${styles.FormButtonArea}`}>
                <Link to={`/admin/testimonial/list`} as={Button} className={`${styles.CancelBU}`}>Cancel</Link>
                <input className={`${styles.SubmitBU}`} value="Submit" type="submit" />
              </div>
            </form>
          </div>

        </div>
        </div>}
      </React.Fragment>
  )}


export default withAdminAuth(TestimonialAdd);