import Home from "../frontend/pages/home/home";
import Login from "../frontend/pages/login/login";
import Signup from "../frontend/pages/signup/signup";
import Reservation from "../frontend/pages/reservation/reservation";
import AbooutUs from "../frontend/pages/aboutus/aboutus";
import Blog from "../frontend/pages/blog/blog";
import CarServices from "../frontend/pages/carservices/carservices";
import Cars from "../frontend/pages/cars/cars";
import Services from "../frontend/pages/services/services";
import FAQ from "../frontend/pages/faq/faq";
import Privacy from "../frontend/pages/privacy/privacy";
import CancellationPolicy from "../frontend/pages/cancel-policy/Cancel";
import Terms from "../frontend/pages/terms/terms";
import Contact from "../frontend/pages/contactus/contactus";
import AdminDashboard from "../admin/pages/dashboard/dashboard";
import CarList from "../admin/pages/car/list";
import CarAdd from "../admin/pages/car/add";
import AddOnList from "../admin/pages/addons/list";
import AddOnAdd from "../admin/pages/addons/add";
import LocationList from "../admin/pages/location/list";
import LocationAdd from "../admin/pages/location/add";
import FerryTicketList from "../admin/pages/ferryticket/list";
import FerryTicketAdd from "../admin/pages/ferryticket/add";
import FAQList from "../admin/pages/faqs/list";
import FAQAdd from "../admin/pages/faqs/add";
import UserList from "../admin/pages/user/list";
import UserAdd from "../admin/pages/user/add";
import CustomerList from "../admin/pages/customer/list";
import BookingList from "../admin/pages/booking/list";
import BlogList from "../admin/pages/blog/list";
import BlogAdd from "../admin/pages/blog/add";
import BookingCalendar from "../admin/pages/booking-calendar/calendar";
import TermsUse from "../admin/pages/pagemanager/termsuse";
import PrivacyPolicy from "../admin/pages/pagemanager/privacypolicy";
import CancelPolicy from "../admin/pages/pagemanager/cancelpolicy";
import Car from "../admin/pages/pagemanager/car";
import About from "../admin/pages/pagemanager/about";
import ContactAdmin from "../admin/pages/pagemanager/contactadmin";
import QuickBooking from "../admin/pages/quick-booking/add";
import CouponAdd from "../admin/pages/coupon/add";
import CouponList from "../admin/pages/coupon/list";
import TestimonialAdd from "../admin/pages/testimonials/add";
import TestimonialList from "../admin/pages/testimonials/list";
//import UserDashboard from '../customer/pages/dashboard/dashboard';
import MyProfile from "../customer/pages/myprofile/myprofile";
import UpdateProfile from "../customer/pages/updateprofile/updateprofile";
import MyBooking from "../customer/pages/mybooking/mybooking";
import PaymentSuccess from "../frontend/pages/payment-success/payment-success";
import PaymentFailed from "../frontend/pages/payment-failed/payment-failed";
import FerryTicket from "../frontend/pages/ferry-ticket/ferry-ticket";

const HomeRoutes = {
  path: "/",
  component: Home,
};
const LoginRoutes = {
  path: "/sign-in",
  component: Login,
};
const SignupRoutes = {
  path: "/sign-up",
  component: Signup,
};

const ReservationRoutes = {
  path: "/reservation",
  component: Reservation,
};

const AbooutUsRoutes = {
  path: "/about-us",
  component: AbooutUs,
};

const BlogRoutes = {
  path: "/blog",
  component: Blog,
};
const ContactUsRoutes = {
  path: "/contact-us",
  component: Contact,
};
const CarServicesRoutes = {
  path: "/car-services",
  component: CarServices,
};
const CarsRoutes = {
  path: "/cars",
  component: Cars,
};
const ServicesRoutes = {
  path: "/services",
  component: Services,
};

const FAQRoutes = {
  path: "/faq",
  component: FAQ,
};

const PrivacyRoutes = {
  path: "/privacy",
  component: Privacy,
};
const CancelPolicyRoutes = {
  path: "/cancellation-policy",
  component: CancellationPolicy,
};

const TermsRoutes = {
  path: "/terms",
  component: Terms,
};

const PaymentSuccessRoutes = {
  path: "/booking-success/:id",
  component: PaymentSuccess,
};

const PaymentFailedRoutes = {
  path: "/booking-failed",
  component: PaymentFailed,
}

const FerryTicketRoutes = {
  path: "/book-ferry-ticket",
  component: FerryTicket,
};

export const AdminRoutes = {
  routeList: [
    {
      path: "/dashboard",
      component: AdminDashboard,
    },

    {
      path: "/user/list",
      component: UserList,
    },
    {
      path: "/user/add",
      component: UserAdd,
    },
    {
      path: "/user/edit/:id",
      component: UserAdd,
    },
    {
      path: "/customer/list",
      component: CustomerList,
    },
    {
      path: "/booking/list",
      component: BookingList,
    },
    {
      path: "/booking-calendar",
      component: BookingCalendar,
    },
    {
      path: "/car/list",
      component: CarList,
    },
    {
      path: "/car/add",
      component: CarAdd,
    },
    {
      path: "/car/edit/:id",
      component: CarAdd,
    },
    {
      path: "/add-on/list",
      component: AddOnList,
    },
    {
      path: "/add-on/add",
      component: AddOnAdd,
    },
    {
      path: "/add-on/edit/:id",
      component: AddOnAdd,
    },
    {
      path: "/location/list",
      component: LocationList,
    },

    {
      path: "/location/add",
      component: LocationAdd,
    },
    {
      path: "/location/edit/:id",
      component: LocationAdd,
    },
    {
      path: "/ferry-ticket/list",
      component: FerryTicketList,
    },

    {
      path: "/ferry-ticket/add",
      component: FerryTicketAdd,
    },
    {
      path: "/ferry-ticket/edit/:id",
      component: FerryTicketAdd,
    },

    {
      path: "/faq/list",
      component: FAQList,
    },
    {
      path: "/faq/add",
      component: FAQAdd,
    },
    {
      path: "/faq/edit/:id",
      component: FAQAdd,
    },
    {
      path: "/blog/list",
      component: BlogList,
    },
    {
      path: "/blog/add",
      component: BlogAdd,
    },
    {
      path: "/blog/edit/:id",
      component: BlogAdd,
    },
    {
      path: "/page-manager/terms-use",
      component: TermsUse,
    },

    {
      path: "/page-manager/privacy-policy",
      component: PrivacyPolicy,
    },
    {
      path: "/page-manager/cancellation-policy",
      component: CancelPolicy,
    },
    {
      path: "/page-manager/about",
      component: About,
    },
    {
      path: "/page-manager/contact",
      component: ContactAdmin,
    },
    {
      path: "/quick-booking",
      component: QuickBooking,
    },

    {
      path: "/page-manager/car",
      component: Car,
    },
    {
      path: "/coupon/list",
      component: CouponList,
    },
    {
      path: "/coupon/add",
      component: CouponAdd,
    },
    {
      path: "/coupon/edit/:id",
      component: CouponAdd,
    },
    {
      path: "/testimonial/list",
      component: TestimonialList,
    },
    {
      path: "/testimonial/add",
      component: TestimonialAdd,
    },
    {
      path: "/testimonial/edit/:id",
      component: TestimonialAdd,
    },
  ],
};

export const CustomerRoutes = {
  routeList: [
    {
      path: "/my-bookings",
      component: MyBooking,
    },
    {
      path: "/my-profile",
      component: MyProfile,
    },
    {
      path: "/update-profile",
      component: UpdateProfile,
    },
  ],
};

export const FrontRouteList = [
  HomeRoutes,
  LoginRoutes,
  ReservationRoutes,
  AbooutUsRoutes,
  BlogRoutes,
  ContactUsRoutes,
  CarServicesRoutes,
  CarsRoutes,
  ServicesRoutes,
  FAQRoutes,
  TermsRoutes,
  PrivacyRoutes,
  CancelPolicyRoutes,
  PaymentSuccessRoutes,
  PaymentFailedRoutes,
  FerryTicketRoutes
];

export const WithoutThemeRouteList = [LoginRoutes, SignupRoutes];
