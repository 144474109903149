import React, {useState,useEffect} from 'react';
import styles from './add.module.css';
import { Breadcrumbs  } from '@mui/material';
import {  useNavigate,Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import { FromLoader } from  '../../components/LoaderC';
import Loader from  '../../components/Loader';
import withAdminAuth from "../../components/withAdminAuth";
import {authData} from "../../components/getAuth";
import { Editor } from '@tinymce/tinymce-react';

function Car({ history, match }) {
  const auth = authData();
  const audience = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [editorVal, setEditorVal] = useState('');
  const [editorVal2, setEditorVal2] = useState('');
  const [editorVal3, setEditorVal3] = useState('');

  const schema = yup.object().shape({
    title: yup.string().required('This field is Required'),
    description: yup.string().required('This field is Required'),
    service_title: yup.string().required('This field is Required'),
    service_description: yup.string().required('This field is Required'),
    service_1_title: yup.string().required('This field is Required'),
    service_1_description: yup.string().required('This field is Required'),
    service_2_title: yup.string().required('This field is Required'),
    service_2_description: yup.string().required('This field is Required'),
    service_3_title: yup.string().required('This field is Required'),
    service_3_description: yup.string().required('This field is Required'),
    service_4_title: yup.string().required('This field is Required'),
    service_4_description: yup.string().required('This field is Required'),
    service_5_title: yup.string().required('This field is Required'),
    service_5_description: yup.string().required('This field is Required'),
    service_6_title: yup.string().required('This field is Required'),
    service_6_description: yup.string().required('This field is Required'),
    insurance_title: yup.string().required('This field is Required'),
    insurance_description: yup.string().required('This field is Required'),

  });
  
  const { register, handleSubmit, watch,setValue,  formState: { errors } } = useForm({
    resolver: yupResolver(schema), mode: 'onChange'
  });

  useEffect(()=>{

      if(contentLoading){
        axios.get(audience + 'v1/page-manager/car', {
          headers: {
            Authorization: `Bearer ${auth?.api_token}`,
          }
        }).then(response => {
          setContentLoading(false);
          let itemData = response.data.data;
          itemData = JSON.parse(itemData.description);
          setValue('title',itemData.title);
          setValue('description',itemData.description);
          setValue('service_title',itemData.service_title);
          setValue('service_description',itemData.service_description);
          setValue('service_1_title',itemData.service_1_title);
          setValue('service_1_description',itemData.service_1_description);
          setValue('service_2_title',itemData.service_2_title);
          setValue('service_2_description',itemData.service_2_description);
          setValue('service_3_title',itemData.service_3_title);
          setValue('service_3_description',itemData.service_3_description);
          setValue('service_4_title',itemData.service_4_title);
          setValue('service_4_description',itemData.service_4_description);
          setValue('service_5_title',itemData.service_5_title);
          setValue('service_5_description',itemData.service_5_description);
          setValue('service_6_title',itemData.service_6_title);
          setValue('service_6_description',itemData.service_6_description);
          setValue('insurance_title',itemData.insurance_title);
          setValue('insurance_description',itemData.insurance_description);
          setEditorVal(itemData.insurance_description);
          setEditorVal2(itemData.description);
          setEditorVal3(itemData.service_description);

         // setEditorVal(itemData.description);
        }).catch(error => {
          setContentLoading(false);
        });
      }
    
  },[audience,setValue,contentLoading,auth]);
  
  async function onSubmit(data) {
    let postData = {
      title: 'car',
      description: JSON.stringify(data),
    }
    setLoading(true);
      return axios.post(audience + 'v1/page-manager', postData, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then(response => {
        setLoading(false);
        toast.success(response.data.message);
        navigate('/admin/page-manager/car');
      }).catch(error => {
        setLoading(false);
      });

  }
  const handleEditorChange = (content, editor) => {
    setEditorVal(content);
    setValue('insurance_description', content);
  }

  const handleEditorChange2 = (content, editor) => {
    setEditorVal2(content);
    setValue('description', content);
  }

  const handleEditorChange3 = (content, editor) => {
    setEditorVal3(content);
    setValue('service_description', content);
  }
  
  return (
      <React.Fragment>
        {loading && <Loader /> }
        {contentLoading && <div className="LoaderCard"><div className="Loader FromLoader"><FromLoader /></div></div> }
        {!contentLoading && <div className={`${styles.MainDiv}`}>
          <div className={`${styles.PageTitleWrap}`}>
            <p className={`${styles.PageTitle}`}>Car & Services</p>
            <Breadcrumbs aria-label="breadcrumb" className='breadcrumb'>
              <Link underline="hover" color="inherit" to="/admin/dashboard">Dashboard</Link>
              {/* <Link underline="hover" color="inherit" to={'/admin/faq/list'}>FAQs</Link>
              <Typography color="text.primary">{isAddMode?'Add':'Edit'}</Typography> */}
            </Breadcrumbs>
          </div>
          
          <div className={`${styles.MainCard}`}>
            <div className={`${styles.AddUserMain}`}>
              <form onSubmit={handleSubmit(onSubmit)}>
              <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroupFull}`}>
                    <label className={`${styles.FormLabel}`}>Title*</label>
                    <input {...register("title")} className={`${styles.FormControl}`} />
                                  
                    {errors.title && !watch().title && <span className={`${styles.ErrorM}`}>{errors.title.message}</span>}
                  </div>
                </div>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroupFull}`}>
                    <label className={`${styles.FormLabel}`}>Description*</label>
                    <input type="hidden" {...register("description")} className={`${styles.FormControl}`} />
                  <Editor onEditorChange={handleEditorChange2} value={editorVal2}
                    apiKey='esnq02ea10s4qrcjx6m5z9h03c3iln9jfml86ysvw8legkpo'
                    init={{
                      height: 400,
                      menubar: false,
                      plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                      toolbar:
                        'undo redo | bold italic underline strikethrough | fontselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat |  fullscreen  | image media link | ltr rtl | code'
                    }}
                  />
                                   
                    {errors.description && !watch().description && <span className={`${styles.ErrorM}`}>{errors.description.message}</span>}
                  </div>
                </div>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroupFull}`}>
                    <label className={`${styles.FormLabel}`}>Service Title*</label>
                    <input {...register("service_title")} className={`${styles.FormControl}`} />
                                  
                    {errors.service_title && !watch().service_title && <span className={`${styles.ErrorM}`}>{errors.service_title.message}</span>}
                  </div>
                </div>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroupFull}`}>
                    <label className={`${styles.FormLabel}`}>Service Description*</label>
                    <input type="hidden" {...register("service_description")} className={`${styles.FormControl}`} />
                  <Editor onEditorChange={handleEditorChange3} value={editorVal3}
                    apiKey='esnq02ea10s4qrcjx6m5z9h03c3iln9jfml86ysvw8legkpo'
                    init={{
                      height: 400,
                      menubar: false,
                      plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                      toolbar:
                        'undo redo | bold italic underline strikethrough | fontselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat |  fullscreen  | image media link | ltr rtl | code'
                    }}
                  />
                                   
                    {errors.service_description && !watch().service_description && <span className={`${styles.ErrorM}`}>{errors.service_description.message}</span>}
                  </div>
                </div>
                
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroupFull}`}>
                    <label className={`${styles.FormLabel}`}>Service 1 Title*</label>
                    <input {...register("service_1_title")} className={`${styles.FormControl}`} />
                                  
                    {errors.service_1_title && !watch().service_1_title && <span className={`${styles.ErrorM}`}>{errors.service_1_title.message}</span>}
                  </div>
                </div>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroupFull}`}>
                    <label className={`${styles.FormLabel}`}>Service 1 Description*</label>
                    <textarea {...register("service_1_description")} className={`${styles.FormControlTextArea}`} rows="5"></textarea>
                                  
                    {errors.service_1_description && !watch().service_1_description && <span className={`${styles.ErrorM}`}>{errors.service_1_description.message}</span>}
                  </div>
                </div>

                
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroupFull}`}>
                    <label className={`${styles.FormLabel}`}>Service 2 Title*</label>
                    <input {...register("service_2_title")} className={`${styles.FormControl}`} />
                                  
                    {errors.service_2_title && !watch().service_2_title && <span className={`${styles.ErrorM}`}>{errors.service_2_title.message}</span>}
                  </div>
                </div>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroupFull}`}>
                    <label className={`${styles.FormLabel}`}>Service 2 Description*</label>
                    <textarea {...register("service_2_description")} className={`${styles.FormControlTextArea}`} rows="5"></textarea>
                                  
                    {errors.service_2_description && !watch().service_2_description && <span className={`${styles.ErrorM}`}>{errors.service_2_description.message}</span>}
                  </div>
                </div>

                
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroupFull}`}>
                    <label className={`${styles.FormLabel}`}>Service 3 Title*</label>
                    <input {...register("service_3_title")} className={`${styles.FormControl}`} />
                                  
                    {errors.service_3_title && !watch().service_3_title && <span className={`${styles.ErrorM}`}>{errors.service_3_title.message}</span>}
                  </div>
                </div>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroupFull}`}>
                    <label className={`${styles.FormLabel}`}>Service 3 Description*</label>
                    <textarea {...register("service_3_description")} className={`${styles.FormControlTextArea}`} rows="5"></textarea>
                                  
                    {errors.service_3_description && !watch().service_3_description && <span className={`${styles.ErrorM}`}>{errors.service_3_description.message}</span>}
                  </div>
                </div>

                
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroupFull}`}>
                    <label className={`${styles.FormLabel}`}>Service 1 Title*</label>
                    <input {...register("service_4_title")} className={`${styles.FormControl}`} />
                                  
                    {errors.service_4_title && !watch().service_4_title && <span className={`${styles.ErrorM}`}>{errors.service_4_title.message}</span>}
                  </div>
                </div>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroupFull}`}>
                    <label className={`${styles.FormLabel}`}>Service 4 Description*</label>
                    <textarea {...register("service_4_description")} className={`${styles.FormControlTextArea}`} rows="5"></textarea>
                                  
                    {errors.service_4_description && !watch().service_4_description && <span className={`${styles.ErrorM}`}>{errors.service_4_description.message}</span>}
                  </div>
                </div>

                
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroupFull}`}>
                    <label className={`${styles.FormLabel}`}>Service 5 Title*</label>
                    <input {...register("service_5_title")} className={`${styles.FormControl}`} />
                                  
                    {errors.service_5_title && !watch().service_5_title && <span className={`${styles.ErrorM}`}>{errors.service_5_title.message}</span>}
                  </div>
                </div>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroupFull}`}>
                    <label className={`${styles.FormLabel}`}>Service 5 Description*</label>
                    <textarea {...register("service_5_description")} className={`${styles.FormControlTextArea}`} rows="5"></textarea>
                                  
                    {errors.service_5_description && !watch().service_5_description && <span className={`${styles.ErrorM}`}>{errors.service_5_description.message}</span>}
                  </div>
                </div>

                
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroupFull}`}>
                    <label className={`${styles.FormLabel}`}>Service 6 Title*</label>
                    <input {...register("service_6_title")} className={`${styles.FormControl}`} />
                                  
                    {errors.service_6_title && !watch().service_6_title && <span className={`${styles.ErrorM}`}>{errors.service_6_title.message}</span>}
                  </div>
                </div>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroupFull}`}>
                    <label className={`${styles.FormLabel}`}>Service 6 Description*</label>
                    <textarea {...register("service_6_description")} className={`${styles.FormControlTextArea}`} rows="5"></textarea>
                                  
                    {errors.service_6_description && !watch().service_6_description && <span className={`${styles.ErrorM}`}>{errors.service_6_description.message}</span>}
                  </div>
                </div>

                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroupFull}`}>
                    <label className={`${styles.FormLabel}`}>Insurance Title*</label>
                    <input {...register("insurance_title")} className={`${styles.FormControl}`} />
                                  
                    {errors.insurance_title && !watch().insurance_title && <span className={`${styles.ErrorM}`}>{errors.insurance_title.message}</span>}
                  </div>
                </div>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroupFull}`}>
                    <label className={`${styles.FormLabel}`}>Insurance Description*</label>
                    <input type="hidden" {...register("insurance_description")} className={`${styles.FormControl}`} />
                  <Editor onEditorChange={handleEditorChange} value={editorVal}
                    apiKey='esnq02ea10s4qrcjx6m5z9h03c3iln9jfml86ysvw8legkpo'
                    init={{
                      height: 400,
                      menubar: false,
                      plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                      toolbar:
                        'undo redo | bold italic underline strikethrough | fontselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat |  fullscreen  | image media link | ltr rtl | code'
                    }}
                  />
                                   
                    {errors.insurance_description && !watch().insurance_description && <span className={`${styles.ErrorM}`}>{errors.insurance_description.message}</span>}
                  </div>
                </div>
                             
              <div className={`${styles.FormButtonArea}`}>
                {/* <button className={`${styles.CancelBU}`} type="button">Cancel</button> */}
                <input className={`${styles.SubmitBU}`} value="Update" type="submit" />
              </div>
            </form>
          </div>

        </div>
        </div>}
      </React.Fragment>
  )}


export default withAdminAuth(Car);