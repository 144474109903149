import React, { useEffect, useState } from "react";
import styles from "../reservation.module.css";
import { Checkbox } from "@mui/material";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import axios from "axios";
import LeftPanelBlock from "./LeftPanelBlock";
import Loader from "../../../../admin/components/Loader";
import { authData } from "../../../../admin/components/getAuth";

const Step4 = ({handleSelect, bookingData, carList, pickupLocationList, dropoffLocationList, addOnList, currency, currencyRate, currencyLoading, updateReservationInfo, applyPromoCode, removePromoCode}) => {
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState(0);
    const navigate = useNavigate();
    const auth = authData();
    const [checked, setChecked] = useState(false);
    const [checked2, setChecked2] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalAmountConverted, setTotalAmountConverted] = useState(0);

    useEffect(() => {
        if(auth){
            setUserId(auth?.id);
        }
    }, [auth]);
    
    useEffect(() => {
        setLoading(false);
      
        let carAmountTemp = 0;
        let totalAmountTemp = 0;
        let subTotalAmountTemp = 0;
        if(bookingData?.securityDeposit){
            totalAmountTemp += bookingData?.securityDeposit;
        }
      
        if(bookingData?.cars && bookingData?.cars?.length){
            subTotalAmountTemp += bookingData?.cars[0]?.sub_amount;
            carAmountTemp = bookingData?.cars[0]?.sub_amount;
        }
      
        if(bookingData?.addOns && bookingData?.addOns?.length){
            addOnList.forEach((item) => {
                let isAddOn = bookingData?.addOns.find((i) => i.addon_id === item.id);
                if(isAddOn){
                    subTotalAmountTemp += item.payment_type === "Day Wise" ? bookingData?.days * item?.price * isAddOn?.quantity : item?.price * isAddOn?.quantity;
                }
            });
        }
      
        totalAmountTemp += subTotalAmountTemp;
        let discountAmountTemp = 0;
        let discountAmountTemp2 = 0;
        if(bookingData?.promotionalCode?.code){
            let disPercent = bookingData?.promotionalCode?.discount;
            disPercent = parseInt(disPercent);
            if(bookingData?.promotionalCode?.item_type === ""){
                discountAmountTemp2 = (subTotalAmountTemp * disPercent) / 100;
            }
        
            if(bookingData?.promotionalCode?.item_type === "car"){
                discountAmountTemp2 = (carAmountTemp * disPercent) / 100;
            }
        
            if(bookingData?.promotionalCode?.item_type === "addOn"){
                let addOnAmoutTemp = 0;
          
                addOnList.forEach((item) => {
                    let isAddOn = bookingData?.addOns.find((i) => i.addon_id === item.id);
                    if(isAddOn && item.id === bookingData?.promotionalCode?.item_id){
                        addOnAmoutTemp += item.payment_type === "Day Wise" ? bookingData?.days * item?.price * isAddOn?.quantity : item?.price * isAddOn?.quantity;
                    }
                });
                discountAmountTemp2 = (addOnAmoutTemp * disPercent) / 100;
            }
            totalAmountTemp -= discountAmountTemp2;
        }else{
            if(bookingData?.isDiscount === 1){
                discountAmountTemp = (subTotalAmountTemp * 10) / 100;
                totalAmountTemp -= discountAmountTemp;
            }
        }
      
        let dropOffLocation_delivery_feeTemp = 0;
      
        if(bookingData?.dropOffLocation_is_airport === 0 || bookingData?.days < 7){
            totalAmountTemp += bookingData?.dropOffLocation_delivery_fee;
            dropOffLocation_delivery_feeTemp = bookingData?.dropOffLocation_delivery_fee;
        }
      
        let paymentFeeTemp = ((subTotalAmountTemp + dropOffLocation_delivery_feeTemp - discountAmountTemp) * 3) / 100;
        paymentFeeTemp = paymentFeeTemp.toFixed(2);
        paymentFeeTemp = parseFloat(paymentFeeTemp);
      
        totalAmountTemp += paymentFeeTemp;
      
        setTotalAmount(totalAmountTemp);
      
        axios(process.env.REACT_APP_API_URL + "v1/currency-convertter/WST/USD").then((response) => {
            let cRate = response.data.data;
            let totalAmountTemp2 = cRate * totalAmountTemp;
            totalAmountTemp2 = totalAmountTemp2.toFixed(2);
            setTotalAmountConverted(totalAmountTemp2);
        });
    }, [bookingData, addOnList]);
    
    const createOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [{
                description: "Booking on Motu Car Rental",
                amount: {
                    currency_code: "USD",
                    value: totalAmountConverted
                }
            }],
            application_context: {
                shipping_preference: "NO_SHIPPING",
            }
        }).then((orderID) => {
            return orderID;
        });
    }
  
    const onApprove = (data, actions) => {
        setLoading(true);
        return actions.order.capture().then(function (details) {
            let purchase_units = details.purchase_units;
            purchase_units = purchase_units[0];
            onSubmit(purchase_units?.payments?.captures[0]?.id);
        });
    }
  
    const onSubmit = (paymentIdNew) => {
        setLoading(true);
        let totalAmount = 0;
        let subTotalAmount = 0;
        let pCode = "";
        let pCodeId = 0;
        let carAmountTemp = 0;
    
        if(bookingData?.cars?.length){
            bookingData?.cars.forEach((item) => {
                totalAmount += item.sub_amount;
                subTotalAmount += item.sub_amount;
                carAmountTemp = item.sub_amount;
            });
        }
    
        if(bookingData?.addOns?.length){
            bookingData?.addOns.forEach((item) => {
                totalAmount += item.sub_amount;
                subTotalAmount += item.sub_amount;
            });
        }
    
        if(bookingData?.securityDeposit){
            totalAmount += bookingData?.securityDeposit;
        }
    
        let discountAmnt = 0;
        let discountAmnt2 = 0;
    
        if(bookingData?.promotionalCode?.code){
            pCode = bookingData?.promotionalCode?.code;
            pCodeId = bookingData?.promotionalCode?.id;
            let disPercent = bookingData?.promotionalCode?.discount;
            disPercent = parseInt(disPercent);
            if(bookingData?.promotionalCode?.item_type === ""){
                discountAmnt2 = (subTotalAmount * disPercent) / 100;
            }
            if(bookingData?.promotionalCode?.item_type === "car"){
                discountAmnt2 = (carAmountTemp * disPercent) / 100;
            }
            if(bookingData?.promotionalCode?.item_type === "addOn"){
                let addOnAmoutTemp = 0;
                addOnList.forEach((item) => {
                    let isAddOn = bookingData?.addOns.find((i) => i.addon_id === item.id);
                    if(isAddOn && item.id === bookingData?.promotionalCode?.item_id){
                        addOnAmoutTemp += bookingData?.days * item?.price * isAddOn?.quantity;
                    }
                });
                discountAmnt2 = (addOnAmoutTemp * disPercent) / 100;
            }
            totalAmount -= discountAmnt2;
        }else{
            if(bookingData?.isDiscount === 1){
                discountAmnt = (subTotalAmount * 10) / 100;
                totalAmount -= discountAmnt;
            }
        }
      
        let dropoffAmnt = 0;
        if(bookingData?.dropOffLocation_is_airport === 0 || bookingData?.days < 7){
            dropoffAmnt = bookingData?.dropOffLocation_delivery_fee;
            totalAmount += dropoffAmnt;
        }
      
        let paymentFee = ((subTotalAmount + dropoffAmnt - discountAmnt) * 3) / 100;
        paymentFee = paymentFee.toFixed(2);
        paymentFee = parseFloat(paymentFee);
      
        totalAmount += paymentFee;
      
        let postData = {...bookingData, ...bookingData.userData, dropoff_location_id: bookingData?.dropOffLocation, dropoff_location: bookingData?.dropOffLocation_name, user_id: userId, amount: subTotalAmount, security_amount: bookingData?.securityDeposit, discount_amount: discountAmnt, dropoff_amount: dropoffAmnt, payment_fee: paymentFee, total_amount: totalAmount, payable_amount: totalAmount * currencyRate, currency: currency, transaction_id: paymentIdNew, pCodeId: pCodeId, pCode: pCode, discountAmnt2: discountAmnt2, total_amount_usd: totalAmountConverted};
      
        axios.post(process.env.REACT_APP_API_URL + "v1/bookings", postData).then((response) => {
            let refId = response?.data?.data?.reference_id;
            setLoading(false);
            localStorage.removeItem("bookingData");
            navigate("/booking-success/" + refId);
        }).catch((error) => {
            setLoading(false);
            navigate("/booking-failed");
        });
    }
    
    const PayCheck = (event) => {
        setChecked(event.target.checked);
    }
    
    const PayCheck2 = (event) => {
        setChecked2(event.target.checked);
    };
    
    const [promoCode, setPromoCode] = useState(bookingData?.promotionalCode?.code);
    const [promoCodeError, setPromoCodeError] = useState("");
    const [promoCodeApllied, setPromoCodeApllied] = useState(bookingData?.promotionalCode?.code ? true : false);
    
    const applyCode = () => {
        setPromoCodeError("");
        if(promoCode === ""){
            setPromoCodeError("Please enter promo code.");
            return false;
        }
        let addOnIds = "";
        if(bookingData.addOns && bookingData.addOns.length){
            let addOnArr = bookingData.addOns.map((i) => {
                return i.addon_id;
            });
            addOnIds = addOnArr.join(",");
        }
        let postData = {coupon: promoCode, booking_date: bookingData.pickUpDate, car_id: bookingData.cars[0].car_id, addOnIds: addOnIds};
        setLoading(true);
     
        axios.post(process.env.REACT_APP_API_URL + "v1/check-coupon-code", postData).then((response) => {
            let responseData = response.data;
            if(responseData.type === "error"){
                setPromoCodeError(responseData.message);
            }
            if(responseData.type === "success"){
                setPromoCodeError("");
                setPromoCodeApllied(true);
                applyPromoCode(responseData.data);
            }
            setLoading(false);
        });
    }
    
    const removeCode = () => {
        setPromoCodeError("");
        setPromoCodeApllied(false);
        setPromoCode("");
        removePromoCode();
    }
    
    return (<React.Fragment>
        {loading && <Loader />}
        <div className={`${styles.SelectCarSec}`}>
            <LeftPanelBlock
                handleSelect={handleSelect}
                bookingData={bookingData}
                carList={carList}
                pickupLocationList={pickupLocationList}
                dropoffLocationList={dropoffLocationList}
                addOnList={addOnList}
                currency={currency}
                currencyRate={currencyRate}
                currencyLoading={currencyLoading}
                currentStep={4}
                updateReservationInfo={updateReservationInfo}
            />
            
            <div className={`${styles.AddonsBigSide}`}>
                <div className={`${styles.ClientDetailsForm}`} style={{ paddingBottom: "0", marginBottom: "0" }}>
                    <h2 className={`${styles.BookingFormTitle}`}>Payment Information</h2>
                    <div className={`${styles.PayableAmmo}`}>
                        <p className={`${styles.Text}`}>Payable Amount:{" "}<span className={`${styles.Amount}`}>{!currencyLoading && currency + " " + (totalAmount * currencyRate).toFixed(2)}</span></p>
                    </div>
                </div>
                <div className={`${styles.ClientDetailsForm}`} style={{ width: "100%", maxWidth: "400px" }}>
                    <h2 className={`${styles.BookingFormTitle}`} style={{ marginBottom: "0" }}>Promotional Code</h2>
                    <div className={`${styles.FormRow}`}>
                        <div className={`${styles.FormGroupFull}`} style={{ position: "relative" }}>
                            <label class={`${styles.FormLabel}`}>Have A Promo Code</label>
                            <input type="text" className={`${styles.FormControl}`} value={promoCode} onChange={(e) => { setPromoCode(e.target.value); setPromoCodeError(""); }} readOnly={promoCodeApllied} />
                            {!promoCodeApllied && (<span style={{position: "absolute", top: "38px", right: "10px", cursor: "pointer", color: "#008000"}} onClick={applyCode}>Apply</span>)}
                            {promoCodeApllied && (<span style={{position: "absolute", top: "38px", right: "10px", cursor: "pointer", color: "#008000"}} onClick={removeCode}>Remove</span>)}
                            {promoCodeError !== "" && (<span className={`${styles.ErrorM}`}>{promoCodeError}</span>)}
                        </div>
                    </div>
                </div>
                
                <div className={`${styles.AcceptCheckbox}`}>
                    <Checkbox checked={checked} onChange={PayCheck} />
                    <p>Yes, I would like to receive newsletters and special offers by email.</p>
                </div>
                
                <div className={`${styles.AcceptCheckbox}`}>
                    <Checkbox checked={checked2} onChange={PayCheck2} />
                    <p>I accept Motu Car Rentals{" "}<RouterLink to={"/terms"} target="_blank">Terms</RouterLink>{" "}and{" "}<RouterLink to={"/privacy"} target="_blank">Privacy Policy</RouterLink></p>
                </div>
                
                {totalAmount > 0 && checked2 && (<PayPalButtons style={{ layout: "vertical" }} createOrder={createOrder} onApprove={onApprove} />)}
            </div>
        </div>
    </React.Fragment>);
}

export default Step4;