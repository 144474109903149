import { useEffect, useState } from "react";
import styles from "../reservation.module.css";
import LeftPanelBlock from "./LeftPanelBlock";
import CarBlock from "./CarBlock";

const Step1 = ({handleSelect, bookingData, carList, pickupLocationList, dropoffLocationList, addOnList, selectCar, currency, currencyRate, currencyLoading, bookingCars, updateReservationInfo}) => {
    const [selectedItem, setSelectedItem] = useState([]);
    
    useEffect(() => {
        if(bookingData?.cars && bookingData?.cars?.length){
            let carListTemp = carList.map((item) => {
                let isCar = bookingData?.cars.find((i) => i.car_id === item.id);
                if(isCar){
                    return item;
                }
                return null;
            });
            carListTemp = carListTemp.filter((el) => {
                return el != null;
            });
            setSelectedItem(carListTemp);
        }else{
            setSelectedItem([]);
        }
    }, [bookingData, carList, pickupLocationList, dropoffLocationList]);
    
    return (<div className={`${styles.SelectCarSec}`}>
        <LeftPanelBlock
            handleSelect={handleSelect}
            bookingData={bookingData}
            carList={carList}
            pickupLocationList={pickupLocationList}
            dropoffLocationList={dropoffLocationList}
            addOnList={addOnList}
            currency={currency}
            currencyRate={currencyRate}
            currencyLoading={currencyLoading}
            currentStep={1}
            updateReservationInfo={updateReservationInfo}
        />
        
        <div className={`${styles.ReservationBigSide}`}>
            <div className={`${styles.BigSideHead}`}>
                <div className={`${styles.BigHeadTextSec}`}>
                    <p className={`${styles.BigHeadTitleText}`}>Choose a Vehicle</p>
                    <p className={`${styles.BigHeadSmallText}`}>{carList?.length} Results</p>
                </div>
            </div>
            <div className={`${styles.ReservationBigListSec}`}>
                {carList?.map((item, index) => {
                    let isSelected = selectedItem.includes(item);
                    return (<CarBlock
                        key={index}
                        item={item}
                        isSelected={isSelected}
                        selectCar={selectCar}
                        currency={currency}
                        currencyRate={currencyRate}
                        currencyLoading={currencyLoading}
                        isDisabled={bookingCars.indexOf(item.id) > -1}
                    />);
                })}
            </div>
        </div>
    </div>);
}

export default Step1;