import React, { useState, useEffect } from "react";
import styles from "./add.module.css";
import { Typography, Breadcrumbs, Button } from "@mui/material";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-smart-toaster";
import { FromLoader } from "../../components/LoaderC";
import Loader from "../../components/Loader";
import withAdminAuth from "../../components/withAdminAuth";
import { authData } from "../../components/getAuth";

function UserAdd({ history, match }) {
  const auth = authData();
  const audience = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  let { id } = useParams();
  const isAddMode = !id;
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [validationErrors, setValidationErrors] = useState({});

  let schema;
  if (isAddMode) {
    schema = yup.object().shape({
      name: yup
        .string()
        .required("This field is Required")
        .max(50, "Name cannot be more than 50 characters"),
      email: yup
        .string()
        .required("This field is Required")
        .email("Invalid email"),
      phone: yup.string().required("This field is Required"),
      password: yup
        .string()
        .required("This field is Required")
        .min(6, "Password length should be 6-15")
        .max(15, "Password length should be 6-15"),
      conf_password: yup
        .string()
        .when("password", (password, field) =>
          password
            ? field.oneOf([yup.ref("password")], "Passwords must match")
            : field
        ),
    });
  } else {
    schema = yup.object().shape({
      name: yup
        .string()
        .required("This field is Required")
        .max(50, "Name cannot be more than 50 characters"),
      email: yup
        .string()
        .required("This field is Required")
        .email("Invalid email"),
      phone: yup.string().required("This field is Required"),
      password: yup.string(),
      conf_password: yup
        .string()
        .when("password", (password, field) =>
          password
            ? field.oneOf([yup.ref("password")], "Passwords must match")
            : field
        ),
    });
  }

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  useEffect(() => {
    if (!isAddMode) {
      if (contentLoading) {
        axios
          .get(audience + "v1/users/" + id, {
            headers: {
              Authorization: `Bearer ${auth?.api_token}`,
            },
          })
          .then((response) => {
            setContentLoading(false);
            let itemData = response.data.data;
            setValue("name", itemData.name);
            setValue("email", itemData.email);
            setValue("phone", itemData.phone);
          })
          .catch((error) => {
            setContentLoading(false);
          });
      }
    } else {
      setContentLoading(false);
    }
  }, [isAddMode, audience, id, setValue, contentLoading, auth]);

  async function onSubmit(data) {
    setValidationErrors({});
    delete data.conf_password;
    data = { ...data, role: 1 };

    if (!isAddMode) {
      if (data.password === "") {
        delete data.password;
      }
    }

    const postData = data;

    setLoading(true);

    if (isAddMode) {
      return axios
        .post(audience + "v1/users", postData, {
          headers: {
            Authorization: `Bearer ${auth?.api_token}`,
          },
        })
        .then((response) => {
          setLoading(false);
          toast.success(response.data.message);
          navigate("/admin/user/list");
        })
        .catch((error) => {
          setLoading(false);
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.messages
          ) {
            setValidationErrors(error.response.data.messages);
          }
        });
    } else {
      return axios
        .post(audience + "v1/users/" + id, postData, {
          headers: {
            Authorization: `Bearer ${auth?.api_token}`,
          },
        })
        .then((response) => {
          setLoading(false);
          toast.success(response.data.message);
          navigate("/admin/user/list");
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      {contentLoading && (
        <div className="LoaderCard">
          <div className="Loader FromLoader">
            <FromLoader />
          </div>
        </div>
      )}
      {!contentLoading && (
        <div className={`${styles.MainDiv}`}>
          <div className={`${styles.PageTitleWrap}`}>
            <p className={`${styles.PageTitle}`}>
              User {isAddMode ? "Add" : "Edit"}
            </p>
            <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
              <Link underline="hover" color="inherit" to="/admin/dashboard">
                Dashboard
              </Link>
              <Link underline="hover" color="inherit" to={"/admin/user/list"}>
                Users
              </Link>
              <Typography color="text.primary">
                {isAddMode ? "Add" : "Edit"}
              </Typography>
            </Breadcrumbs>
          </div>

          <div className={`${styles.MainCard}`}>
            <div className={`${styles.AddUserMain}`}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroupFull}`}>
                    <label className={`${styles.FormLabel}`}>Full Name*</label>
                    <input
                      {...register("name")}
                      className={`${styles.FormControl}`}
                    />
                    {errors.name && !watch().name && (
                      <span className={`${styles.ErrorM}`}>
                        {errors.name.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>Email*</label>
                    <input
                      {...register("email")}
                      className={`${styles.FormControl}`}
                      autoComplete="off"
                      readOnly={!isAddMode}
                    />
                    {errors.email && (
                      <span className={`${styles.ErrorM}`}>
                        {errors.email.message}
                      </span>
                    )}
                    {validationErrors?.email && validationErrors?.email[0] && (
                      <span className={`${styles.ErrorM}`}>
                        {validationErrors?.email[0]}
                      </span>
                    )}
                  </div>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>Phone No*</label>
                    <input
                      {...register("phone")}
                      className={`${styles.FormControl}`}
                    />
                    {errors.phone && !watch().phone && (
                      <span className={`${styles.ErrorM}`}>
                        {errors.phone.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>Password*</label>
                    <input
                      type="password"
                      {...register("password")}
                      className={`${styles.FormControl}`}
                      autoComplete="off"
                    />
                    {errors.password && (
                      <span className={`${styles.ErrorM}`}>
                        {errors.password.message}
                      </span>
                    )}
                  </div>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>
                      Confirm Password*
                    </label>
                    <input
                      type="password"
                      {...register("conf_password")}
                      className={`${styles.FormControl}`}
                    />
                    {errors.conf_password && (
                      <span className={`${styles.ErrorM}`}>
                        {errors.conf_password.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className={`${styles.FormButtonArea}`}>
                  {/* <button className={`${styles.CancelBU}`} type="button">Cancel</button> */}
                  <Link
                    to={`/admin/user/list`}
                    as={Button}
                    className={`${styles.CancelBU}`}
                  >
                    Cancel
                  </Link>
                  <input
                    className={`${styles.SubmitBU}`}
                    value="Submit"
                    type="submit"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default withAdminAuth(UserAdd);
