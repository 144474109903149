import React ,{useState} from 'react';
import styles from './login.module.css';
import {  useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import Loader from  '../../../admin/components/Loader';
import { Link } from 'react-router-dom';
import { Home } from 'react-feather';

function Login() {
  const [loading, setLoading] = useState(false);
  const [errorMsg, seterrorMsg] = useState('');
  const audience = process.env.REACT_APP_API_URL;
   const navigate = useNavigate();
   const schema = yup.object().shape({
    email: yup.string().required('This field is Required').email('Invalid E-mail'),
    password: yup.string().required('This field is Required'),

  });
    const { register, handleSubmit, formState: { errors } } = useForm({
      resolver: yupResolver(schema), mode: 'onChange'
    });

    async function onSubmit(data) {
      const token = '';
      const postData = data;
      setLoading(true);
      seterrorMsg('');
      
      return axios.post(audience + 'v1/login', postData, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then(response => {
        const resData = response.data.data;
        localStorage.setItem('authData', JSON.stringify(resData));
        setLoading(false);
        setTimeout(()=>{
          if(resData.role === 1){
            navigate('/admin/dashboard');
          }else if(resData.role === 2){
            let reDirectUrl = localStorage.getItem("reDirectUrl");
            if(reDirectUrl){
              navigate(reDirectUrl);
              localStorage.removeItem("reDirectUrl")
            }else{
              navigate('/customer/my-bookings');
            }
          }else{
            navigate('/');
          }
        },100);
        

      }).catch(error => {
        if(error && error?.response && error?.response?.data && error?.response?.data?.message){
          seterrorMsg(error?.response?.data?.message);
        }
        setLoading(false);
      });
    }

    return (
      <React.Fragment>
        {loading && <Loader /> }
        <div className={`${styles.MainDiv}`}>

         <div className={`${styles.LoginBGSec}`}>
          <img src="/images/loginBG.jpg" alt="" />
            <img src="/images/moto-logo.svg" alt="" className={`${styles.LoginLogo}`} />
         </div>

         <div className={`${styles.LoginFormSec}`}>
         <Link to={'/'} className={`${styles.BackToHome}`}><Home/> Back to home</Link>
          <div className={`${styles.LohinForm}`}>
            <img src="/images/moto-logo.svg" alt="" className={`${styles.LoginLogoSmRe}`} />
            <h2 className={`${styles.BookingFormTitle}`}>Login</h2>
            <p className={`${styles.LoginformSmTitle}`}>Login your account</p>
            <form onSubmit={handleSubmit(onSubmit)} >
              <p className={`${styles.ErrorM}`}>{errorMsg}</p>

              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormGroup}`}>
                  <label class={`${styles.FormLabel}`}>Email</label>
                  <input {...register("email")} className={`${styles.FormControl}`} />
                  {errors.email && <span className={`${styles.ErrorM}`}>{errors.email.message}</span>}
                </div>
                <div className={`${styles.FormGroup}`}>
                  <label class={`${styles.FormLabel}`}>Password</label>
                  <input {...register("password")} type="password" className={`${styles.FormControl}`} />
                  {errors.password && <span className={`${styles.ErrorM}`}>{errors.password.message}</span>}
                </div>
              </div>               
               
              <button type="submit" className={`${styles.LoginBU}`}><span>Login</span></button>
              <p className={`${styles.LoginFormSText}`}>Don't have an account? <Link to={'/sign-up'}>Sign up</Link></p>
            </form>
          </div>
         </div>

        </div>
      </React.Fragment>
  )}


export default Login;