import React,{useEffect} from 'react';
import styles from './updateprofile.module.css';
import { useForm,Controller } from "react-hook-form";
import withCustomerAuth from "../../components/withCustomerAuth";
import {authData} from "../../../admin/components/getAuth";
import { NumericFormat,PatternFormat  } from 'react-number-format';
import Loader from  '../../../admin/components/Loader';
import {  useNavigate} from "react-router-dom";
import axios from 'axios';

function MyProfile() {
  const navigate = useNavigate();
  const auth = authData();
  const [loading, setLoading] = React.useState(false);
  const { register, handleSubmit, control, formState: { errors },setValue } = useForm();

  useEffect(()=>{

    if(auth){
      setValue('first_name',auth?.customer_details?.first_name);
      setValue('last_name',auth?.customer_details?.last_name);
      setValue('phone',auth?.customer_details?.phone);
      setValue('email',auth?.customer_details?.email);
      setValue('address',auth?.customer_details?.address);
      setValue('city',auth?.customer_details?.city);
      setValue('state',auth?.customer_details?.state);
      setValue('zipcode',auth?.customer_details?.zipcode);
      setValue('age',auth?.customer_details?.age);
    }

  },[auth,setValue]);

  const onSubmit = (data) => {
    setLoading(true);

    axios.post(process.env.REACT_APP_API_URL+'v1/update-profile', data, {
      headers: {
        Authorization: `Bearer ${auth?.api_token}`
      }
    }).then(response => {
      setLoading(false);
      let resData = response.data.data;
      let authData = localStorage.getItem("authData");
      authData = JSON.parse(authData);
      authData = {...authData,name:resData.name,email:resData.email,phone:resData.phone,customer_details:resData.customer_details};
      localStorage.setItem('authData', JSON.stringify(authData));
      navigate('/customer/my-profile');
    }).catch(error => {
      setLoading(false);
      navigate('/customer/my-profile');
    });
  };
  
  return (
    <>
    {loading && <Loader /> }
    <div className={`${styles.MainDivCustomer}`}>
      <img src="/images/backgroundGra.png" alt="" className={`${styles.BackGroundGra}`} />
      <div className='container'>
        <div className={`${styles.UserProCard}`}>
          <div className={`${styles.UserHead}`}>
            <p className={`${styles.UserProName}`}>Update Profile</p>
          </div>
          <div className={`${styles.UserProDe}`}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormGroup}`}>
                  <label class={`${styles.FormLabel}`}>First Name*</label>
                  <input type="text" {...register("first_name", { required: true })} className={`${styles.FormControl}`} />
                  {errors?.firstName?.type === "required" && <span className={`${styles.ErrorM}`}>This field is required</span>}
                </div>
                <div className={`${styles.FormGroup}`}>
                  <label class={`${styles.FormLabel}`}>Last Name*</label>
                  <input type="text" {...register("last_name", { required: true })} className={`${styles.FormControl}`} />
                  {errors?.lastName?.type === "required" && <span className={`${styles.ErrorM}`}>This field is required</span>}
                </div>
              </div>
              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormGroup}`}>
                  <label class={`${styles.FormLabel}`}>Phone Number*</label>
                  <Controller
                    control={control}
                    name="phone"
                    {...register("phone", { required: true})}
                    className={`${styles.FormControl}`}
                    render={({ field: { onChange, name, value } }) => (
                      <PatternFormat
                        format="+############"
                        name={name}
                        value={value}
                        onChange={onChange}
                        className={`${styles.FormControl}`}
                      />
                    )}
                  />
                  {errors?.phone?.type === "required" && <span className={`${styles.ErrorM}`}>This field is required</span>}
                </div>
                <div className={`${styles.FormGroup}`}>
                  <label class={`${styles.FormLabel}`}>Email*</label>
                  <input type="email" {...register("email", {
                    required: "This field is required",
                    pattern: {
                      value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Invalid email address"
                    }
                  })} className={`${styles.FormControl}`} readOnly={true} />
                  {errors?.email && <span className={`${styles.ErrorM}`}>{errors.email?.message}</span>}
                </div>
              </div>
              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormGroupFull}`}>
                  <label class={`${styles.FormLabel}`}>Address*</label>
                  <input type="text" {...register("address", { required: true })} className={`${styles.FormControl}`} />
                  {errors?.address?.type === "required" && <span className={`${styles.ErrorM}`}>This field is required</span>}
                </div>
              </div>
              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormGroupOneThird}`}>
                  <label class={`${styles.FormLabel}`}>State*</label>
                  <input type="text" {...register("state", { required: true })} className={`${styles.FormControl}`} />
                  {errors?.state?.type === "required" && <span className={`${styles.ErrorM}`}>This field is required</span>}
                </div>
                <div className={`${styles.FormGroupOneThird}`}>
                  <label class={`${styles.FormLabel}`}>City*</label>
                  <input type="text" {...register("city", { required: true })} className={`${styles.FormControl}`} />
                  {errors?.city?.type === "required" && <span className={`${styles.ErrorM}`}>This field is required</span>}
                </div>
                <div className={`${styles.FormGroupOneThird}`}>
                  <label class={`${styles.FormLabel}`}>Zipcode*</label>
                  <Controller
                    control={control}
                    name="zipcode"
                    {...register("zipcode", { required: true})}
                    className={`${styles.FormControl}`}
                    render={({ field: { onChange, name, value } }) => (
                      <PatternFormat
                        format="######"
                        name={name}
                        value={value}
                        onChange={onChange}
                        className={`${styles.FormControl}`}
                      />
                    )}
                  />
                  {errors?.zipcode?.type === "required" && <span className={`${styles.ErrorM}`}>This field is required</span>}
                </div>
              </div>
              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormGroup}`}>
                  <label class={`${styles.FormLabel}`}>Age*</label>
                  <Controller
                    control={control}
                    name="age"
                    {...register("age", { required: true})}
                    className={`${styles.FormControl}`}
                    render={({ field: { onChange, name, value } }) => (
                      <NumericFormat
                        isAllowed={(values, sourceInfo) => {
                          const { value } = values;
                          return value < 100;
                        }}
                        name={name}
                        value={value}
                        onChange={onChange}
                        className={`${styles.FormControl}`}
                      />
                    )}
                  />
                  {errors?.age?.type === "required" && <span className={`${styles.ErrorM}`}>This field is required</span>}
                </div>
              </div>
              <div className={`${styles.MyProBUSec}`}>
                <button className={`${styles.MyProUpdateBU}`}>Update User</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default withCustomerAuth(MyProfile);