import React from 'react';
import styles from './payment-failed.module.css';

export default function PaymentFailed() {
  
  return (
    <React.Fragment>
      <div className={`${styles.BookingDone}`}>
        <img src="/images/check.png" alt="" />
        <p className={`${styles.BookingTitle}`}>Sorry!</p>
        <p className={`${styles.BookingSmTitle}`}>You booking is not completed</p>
      </div>
    </React.Fragment>
  )
}