import React, {useState,useEffect} from 'react';
import styles from './add.module.css';
import { Typography, Breadcrumbs,Button  } from '@mui/material';
import {  useNavigate,Link ,useParams} from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import { FromLoader } from  '../../components/LoaderC';
import Loader from  '../../components/Loader';
import withAdminAuth from "../../components/withAdminAuth";
import {authData} from "../../components/getAuth";

function AddOnsAdd({ history, match }) {
  const auth = authData();
  const audience = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  let { id } = useParams();
  const isAddMode = !id;
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  
  const schema = yup.object().shape({
    question: yup.string().required('This field is Required'),
    answer: yup.string().required('This field is Required'),
  });
  
  const { register, handleSubmit, watch,setValue,  formState: { errors } } = useForm({
    resolver: yupResolver(schema), mode: 'onChange'
  });

  useEffect(()=>{
    if(!isAddMode){
      if(contentLoading){
        axios.get(audience + 'v1/faqs/'+id, {
          headers: {
            Authorization: `Bearer ${auth?.api_token}`,
          }
        }).then(response => {
          setContentLoading(false);
          let itemData = response.data.data;
          setValue('question',itemData.question);
          setValue('answer',itemData.answer);
        }).catch(error => {
          setContentLoading(false);
        });
      }
    }else{
      setContentLoading(false);
    }
  },[isAddMode,audience,id,setValue,contentLoading,auth]);
  
  async function onSubmit(data) {
    const postData = data;
    setLoading(true);

    if(isAddMode){
      return axios.post(audience + 'v1/faqs', postData, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then(response => {
        setLoading(false);
        toast.success(response.data.message);
        navigate('/admin/faq/list');
      }).catch(error => {
        setLoading(false);
      });
    }else{
      return axios.post(audience + 'v1/faqs/'+id, postData, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then(response => {
        setLoading(false);
        toast.success(response.data.message);
        navigate('/admin/faq/list');
      }).catch(error => {
        setLoading(false);
      });
    }
  }
  
  return (
      <React.Fragment>
        {loading && <Loader /> }
        {contentLoading && <div className="LoaderCard"><div className="Loader FromLoader"><FromLoader /></div></div> }
        {!contentLoading && <div className={`${styles.MainDiv}`}>
          <div className={`${styles.PageTitleWrap}`}>
            <p className={`${styles.PageTitle}`}>FAQ {isAddMode?'Add':'Edit'}</p>
            <Breadcrumbs aria-label="breadcrumb" className='breadcrumb'>
              <Link underline="hover" color="inherit" to="/admin/dashboard">Dashboard</Link>
              <Link underline="hover" color="inherit" to={'/admin/faq/list'}>FAQs</Link>
              <Typography color="text.primary">{isAddMode?'Add':'Edit'}</Typography>
            </Breadcrumbs>
          </div>
          
          <div className={`${styles.MainCard}`}>
            <div className={`${styles.AddUserMain}`}>
              <form onSubmit={handleSubmit(onSubmit)}>
              <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroupFull}`}>
                    <label className={`${styles.FormLabel}`}>Question*</label>
                    <input {...register("question")} className={`${styles.FormControl}`} />
                    {errors.question && !watch().question && <span className={`${styles.ErrorM}`}>{errors.question.message}</span>}
                  </div>
                </div>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroupFull}`}>
                    <label className={`${styles.FormLabel}`}>Answer*</label>
                    <textarea {...register("answer")} className={`${styles.FormControl}`}></textarea>
                    {errors.answer && !watch().answer && <span className={`${styles.ErrorM}`}>{errors.answer.message}</span>}
                  </div>
                </div>
                             
              <div className={`${styles.FormButtonArea}`}>
                {/* <button className={`${styles.CancelBU}`} type="button">Cancel</button> */}
                <Link to={`/admin/faq/list`} as={Button} className={`${styles.CancelBU}`}>Cancel</Link>
                <input className={`${styles.SubmitBU}`} value="Submit" type="submit" />
              </div>
            </form>
          </div>

        </div>
        </div>}
      </React.Fragment>
  )}


export default withAdminAuth(AddOnsAdd);