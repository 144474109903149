import React, { useEffect, useState } from "react";
import styles from "./reservation.module.css";
import { useNavigate } from "react-router-dom";
import { Parallax } from "react-parallax";
import {Box, Stepper, Step, StepLabel, Button, Typography, Dialog, DialogContent } from "@mui/material";
import moment from "moment";
import axios from "axios";
import { authData } from "../../../admin/components/getAuth";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import Step4 from "./components/Step4";

const steps = ["Reserve Car", "Optional Add-Ons", "Enter Information", "Secure Payment"];

function SuccessBlock() {
  return <div>Thank You: All steps completed - you&apos;re finished</div>;
}

export default function Reservation() {
  const auth = authData();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [bookingData, setBookingData] = useState({});
  const [carList, setCarList] = useState([]);
  const [addOnList, setAddOnList] = useState([]);
  const [currency, setCurrency] = useState("");
  const [currencyRate, setCurrencyRate] = useState(1);
  const [currencyLoading, setCurrencyLoading] = useState(false);
  const [bookingCars, setBookingCars] = useState([]);
  const [bookingAddOns, setBookingAddOns] = useState([]);
  const [openSign, setOpenSign] = useState(false);
  const [pickupLocationList, setPickupLocationList] = useState([]);
  const [dropoffLocationList, setDropoffLocationList] = useState([]);
  
  useEffect(() => {
    function fetchCars() {
      axios(process.env.REACT_APP_API_URL + "v1/car-list").then((response) => {
        let allData = response.data.data;
        setCarList(allData);
      }).catch((error) => {
        setCarList([]);
      });
    }
    
    function fetchAddons() {
      axios(process.env.REACT_APP_API_URL + "v1/add-on-list").then((response) => {
        let allData = response.data.data;
        setAddOnList(allData);
      }).catch((error) => {
        setAddOnList([]);
      });
    }
    
    function fetchPickupLocationList() {
      axios(process.env.REACT_APP_API_URL + "v1/pickup-location-list").then((response) => {
        let pickupList = response.data.data.map((i) => {
          return { value: i.id, label: i.name, delivery_fee: i.delivery_fee, is_airport: i.is_airport };
        });
        setPickupLocationList(pickupList);
      }).catch((error) => {
        setPickupLocationList([]);
      });
    }
    
    function fetchDropoffLocationList() {
      axios(process.env.REACT_APP_API_URL + "v1/dropoff-location-list").then((response) => {
        let dropoffList = response.data.data.map((i) => {
          return { value: i.id, label: i.name };
        });
        setDropoffLocationList(dropoffList);
      }).catch((error) => {
        setDropoffLocationList([]);
      });
    }

    let currentCurrency = localStorage.getItem("currentCurrency");
    if(currentCurrency){
      setCurrency(currentCurrency);
      getCurrencyRate(currentCurrency);
    }else{
      setCurrency("USD");
      getCurrencyRate("USD");
    }
    
    fetchCars();
    fetchAddons();
    fetchPickupLocationList();
    fetchDropoffLocationList();
  }, []);

  useEffect(() => {
    let bookingDataTemp = localStorage.getItem("bookingData");
    let startDate = moment().format("YYYY-MM-DD");
    let endDate = moment().format("YYYY-MM-DD");
    if(bookingDataTemp){
      bookingDataTemp = JSON.parse(bookingDataTemp);
      startDate = bookingDataTemp?.pickUpDate;
      endDate = bookingDataTemp?.dropOffDate;
    }
    function fetchBookingCars() {
      axios(process.env.REACT_APP_API_URL + "v1/booking-car-list/" + startDate + "/" + endDate).then((response) => {
        let allData = response.data.data;
        setBookingCars(allData);
      }).catch((error) => {
        setBookingCars([]);
      });
    }

    function fetchBookingAddons() {
      axios(process.env.REACT_APP_API_URL + "v1/booking-add-on-list/" + startDate + "/" + endDate).then((response) => {
        let allData = response.data.data;
        setBookingAddOns(allData);
      }).catch((error) => {
        setBookingAddOns([]);
      });
    }

    if (activeStep === 0) {
      fetchBookingCars();
    }
    if (activeStep === 1) {
      fetchBookingAddons();
    }
  }, [activeStep]);

  useEffect(() => {
    if (!auth) {
      setOpenSign(true);
    }
  }, [auth]);

  useEffect(() => {
    window.addEventListener("click", handleDocumentClick);
    return () => window.removeEventListener("click", handleDocumentClick);
  });

  useEffect(() => {
    let bookingDataTemp = localStorage.getItem("bookingData");
    if (bookingDataTemp) {
      bookingDataTemp = JSON.parse(bookingDataTemp);
      setBookingData(bookingDataTemp);

      if (bookingDataTemp?.activeStep) {
        setActiveStep(bookingDataTemp?.activeStep);
      }
    } else {
      if(pickupLocationList?.length > 0 && dropoffLocationList?.length){
        let startDate = moment();
        let endDate = moment().add(1, "d");
        let startTime = moment().set("hour", 9).set("minute", 0).set("second", 0).set("millisecond", 0);
        let endTime = moment().set("hour", 9).set("minute", 0).set("second", 0).set("millisecond", 0);
  
        let bookingData = {
          location: pickupLocationList[0]?.value,
          location_name: pickupLocationList[0]?.label,
          dropOffLocation: dropoffLocationList[0]?.value,
          dropOffLocation_name: dropoffLocationList[0]?.label,
          dropOffLocation_delivery_fee: pickupLocationList[0]?.delivery_fee,
          dropOffLocation_is_airport: pickupLocationList[0]?.is_airport,
          pickUpDate: moment(startDate).format("YYYY-MM-DD"),
          dropOffDate: moment(endDate).format("YYYY-MM-DD"),
          pickUpTime: moment(startTime).format("HH:mm:ss"),
          dropOffTime: moment(endTime).format("HH:mm:ss"),
          days: 1,
          activeStep: 0
        };
  
        localStorage.setItem("bookingData", JSON.stringify(bookingData));
        setBookingData(bookingData);
      }
    }
  }, [setBookingData, pickupLocationList, dropoffLocationList]);

  const goToSignUp = () => {
    localStorage.setItem("reDirectUrl", "/reservation");
    navigate("/sign-up");
  };

  const handleCloseSign = () => {
    setOpenSign(false);
  };

  const handleDocumentClick = (event) => {
    if (event.target.getAttribute("cngCurrency")) {
      let currentCurrency = localStorage.getItem("currentCurrency");
      if (currentCurrency) {
        setCurrency(currentCurrency);
        getCurrencyRate(currentCurrency);
      } else {
        setCurrency("USD");
        getCurrencyRate("USD");
      }
    }
  };

  const getCurrencyRate = (selectcurrency) => {
    setCurrencyLoading(true);
    axios(process.env.REACT_APP_API_URL + "v1/currency-convertter/WST/" + selectcurrency).then((response) => {
      setCurrencyRate(response.data.data);
      setCurrencyLoading(false);
    }).catch((error) => {
      setCurrencyLoading(false);
    });
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    let bookingDataTemp = bookingData;

    bookingDataTemp = { ...bookingDataTemp, activeStep: activeStep + 1 };
    localStorage.setItem("bookingData", JSON.stringify(bookingDataTemp));

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleSelect = (step) => {
    let bookingDataTemp = bookingData;
    bookingDataTemp = { ...bookingDataTemp, activeStep: step };
    localStorage.setItem("bookingData", JSON.stringify(bookingDataTemp));
    setActiveStep(step);
  };

  const handleBack = () => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
    let bookingDataTemp = bookingData;
    bookingDataTemp = { ...bookingDataTemp, activeStep: activeStep - 1 };
    localStorage.setItem("bookingData", JSON.stringify(bookingDataTemp));
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);

    let bookingDataTemp = bookingData;
    bookingDataTemp = { ...bookingDataTemp, activeStep: 0 };
    localStorage.setItem("bookingData", JSON.stringify(bookingDataTemp));
  };

  const applyPromoCode = (item) => {
    let bookingDataTemp = bookingData;
    let promoObj = {
      id: item.id,
      code: item.code,
      discount: item.discount,
      item_id: item.item_id,
      item_type: item.item_type,
    };

    bookingDataTemp = { ...bookingDataTemp, promotionalCode: promoObj };
    setBookingData(bookingDataTemp);

    localStorage.setItem("bookingData", JSON.stringify(bookingDataTemp));
  };

  const removePromoCode = () => {
    let bookingDataTemp = bookingData;
    bookingDataTemp = { ...bookingDataTemp, promotionalCode: {} };
    setBookingData(bookingDataTemp);

    localStorage.setItem("bookingData", JSON.stringify(bookingDataTemp));
  };

  const selectCar = (item) => {
    let bookingDataTemp = bookingData;
    let carObjArr = [{
      car_id: item.id,
      price: item.price,
      sub_amount: item.price * bookingDataTemp.days
    }];
    bookingDataTemp = {...bookingDataTemp, cars: carObjArr, securityDeposit: parseInt(item.security_amount), promotionalCode: {}};
    setBookingData(bookingDataTemp);

    localStorage.setItem("bookingData", JSON.stringify(bookingDataTemp));
  };

  const selectAddOn = (item) => {
    let bookingDataTemp = bookingData;
    let addOnArr = [];
    if (bookingDataTemp && bookingDataTemp?.addOns) {
      addOnArr = bookingDataTemp.addOns;
    }
    addOnArr.push({
      addon_id: item.id,
      price: item.price,
      payment_type: item.payment_type,
      sub_amount: item.payment_type === "Day Wise" ? item.price * bookingDataTemp.days : item.price,
      quantity: 1,
    });
    bookingDataTemp = { ...bookingDataTemp, addOns: addOnArr };
    setBookingData(bookingDataTemp);

    localStorage.setItem("bookingData", JSON.stringify(bookingDataTemp));
  };

  const updateAddOn = (item, type) => {
    let bookingDataTemp = bookingData;
    let addOnArr = [];
    if (bookingDataTemp && bookingDataTemp?.addOns) {
      addOnArr = bookingDataTemp.addOns;
    }
    addOnArr = addOnArr.map((i) => {
      let newItem = i;
      if (item.id === i.addon_id) {
        if (type === "add") {
          let newQuantity = i.quantity + 1;
          newItem = {
            ...newItem,
            quantity: newQuantity,
            sub_amount: item.payment_type === "Day Wise" ? item.price * bookingDataTemp.days * newQuantity : item.price * newQuantity};
        }
        if (type === "sub") {
          let newQuantity = i.quantity - 1;
          newItem = {
            ...newItem,
            quantity: newQuantity,
            sub_amount: item.payment_type === "Day Wise" ? item.price * bookingDataTemp.days * newQuantity : item.price * newQuantity,
          };
        }
      }
      return newItem;
    });

    bookingDataTemp = { ...bookingDataTemp, addOns: addOnArr };
    setBookingData(bookingDataTemp);

    localStorage.setItem("bookingData", JSON.stringify(bookingDataTemp));
  };

  const removeAddOn = (item) => {
    let bookingDataTemp = bookingData;
    let addOnArr = [];
    if (bookingDataTemp && bookingDataTemp?.addOns) {
      addOnArr = bookingDataTemp.addOns;
    }

    if (addOnArr.length) {
      let addOnArrTemp = addOnArr.filter((i) => {
        return i.addon_id !== item.id;
      });

      bookingDataTemp = { ...bookingDataTemp, addOns: addOnArrTemp };
      setBookingData(bookingDataTemp);
      localStorage.setItem("bookingData", JSON.stringify(bookingDataTemp));
    }
  };

  const updateReservationInfo = (item) => {
    let bookingDataTemp = bookingData;
    let startDate = moment(item.startDate).format("YYYY-MM-DD") + " " + moment(item.startTime).format("HH:mm:ss");
    let endDate = moment(item.endDate).format("YYYY-MM-DD") + " " + moment(item.endTime).format("HH:mm:ss");
    let dayDiff = moment(endDate, "YYYY-MM-DD HH:mm:ss").diff(moment(startDate, "YYYY-MM-DD HH:mm:ss"),"minutes");
    let dayDiff2 = moment(item.startDate).diff(moment(), "days");
    dayDiff = dayDiff / (24 * 60);
    if (dayDiff > parseInt(dayDiff)) {
      dayDiff = parseInt(dayDiff) + 1;
    }
    if (dayDiff < 1) {
      dayDiff = 1;
    }
    
    bookingDataTemp = {
      location: item.location?.value,
      location_name: item.location?.label,
      dropOffLocation: item.dropOffLocation?.value,
      dropOffLocation_name: item.dropOffLocation?.label,
      dropOffLocation_delivery_fee: item.location?.delivery_fee,
      dropOffLocation_is_airport: item.location?.is_airport,
      pickUpDate: moment(item.startDate).format("YYYY-MM-DD"),
      dropOffDate: moment(item.endDate).format("YYYY-MM-DD"),
      pickUpTime: moment(item.startTime).format("HH:mm:ss"),
      dropOffTime: moment(item.endTime).format("HH:mm:ss"),
      days: dayDiff,
      isDiscount: dayDiff2 >= 9 ? 1 : 0,
      discountAmount: 0,
      activeStep: 0,
    };
    
    setBookingData(bookingDataTemp);

    localStorage.setItem("bookingData", JSON.stringify(bookingDataTemp));

    setActiveStep(0);
    setSkipped(new Set());

    axios(process.env.REACT_APP_API_URL + "v1/booking-car-list/" + moment(startDate).format("YYYY-MM-DD") + "/" + moment(endDate).format("YYYY-MM-DD")).then((response) => {
      let allData = response.data.data;
      setBookingCars(allData);
    }).catch((error) => {
      setBookingCars([]);
    });
    
    axios(process.env.REACT_APP_API_URL + "v1/booking-add-on-list/" + moment(startDate).format("YYYY-MM-DD") + "/" + moment(endDate).format("YYYY-MM-DD")).then((response) => {
      let allData = response.data.data;
      setBookingAddOns(allData);
    }).catch((error) => {
      setBookingAddOns([]);
    });
  }
  
  const saveUserData = (data) => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
    let bookingDataTemp = bookingData;
    bookingDataTemp = { ...bookingDataTemp, userData: data };
    setBookingData(bookingDataTemp);
    
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    bookingDataTemp = { ...bookingDataTemp, activeStep: activeStep + 1 };
    localStorage.setItem("bookingData", JSON.stringify(bookingDataTemp));

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  }

  return (<React.Fragment>
    <div className="InnerBanner">
      <Parallax bgImage="/images/reservation-banner.jpg" strength={400}>
        <div style={{ height: 450 }} className={`${styles.BannerConArea}`}>
          <div className="container">
            <div className={`${styles.InnerBannerRow}`}>
              <h2 className={`${styles.InnerBannerTitle}`}>Reservation</h2>
            </div>
          </div>
        </div>
      </Parallax>
    </div>
    
    <div className="ReservationSec">
      <div className="container">
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep} className="ReservationStepper">
            {steps?.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if(isStepOptional(index)){
                labelProps.optional = (<Typography variant="caption"></Typography>);
              }
              if(isStepSkipped(index)){
                stepProps.completed = false;
              }
              
              return (<Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>);
            })}
          </Stepper>
          {activeStep === steps.length ? (<React.Fragment>
            <SuccessBlock />
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>) : (<React.Fragment>
            {activeStep === 0 && (<Step1
              handleSelect={handleSelect}
              bookingData={bookingData}
              carList={carList}
              pickupLocationList={pickupLocationList}
              dropoffLocationList={dropoffLocationList}
              addOnList={addOnList}
              selectCar={selectCar}
              currency={currency}
              currencyRate={currencyRate}
              currencyLoading={currencyLoading}
              bookingCars={bookingCars}
              updateReservationInfo={updateReservationInfo}
            />)}
            {activeStep === 1 && (<Step2
              handleSelect={handleSelect}
              bookingData={bookingData}
              carList={carList}
              pickupLocationList={pickupLocationList}
              dropoffLocationList={dropoffLocationList}
              addOnList={addOnList}
              selectAddOn={selectAddOn}
              updateAddOn={updateAddOn}
              removeAddOn={removeAddOn}
              currency={currency}
              currencyRate={currencyRate}
              currencyLoading={currencyLoading}
              bookingAddOns={bookingAddOns}
              updateReservationInfo={updateReservationInfo}
            />)}
            {activeStep === 2 && (<Step3
              handleSelect={handleSelect}
              bookingData={bookingData}
              carList={carList}
              pickupLocationList={pickupLocationList}
              dropoffLocationList={dropoffLocationList}
              addOnList={addOnList}
              saveUserData={saveUserData}
              currency={currency}
              currencyRate={currencyRate}
              currencyLoading={currencyLoading}
              updateReservationInfo={updateReservationInfo}
            />)}
            {activeStep === 3 && (<Step4
              handleSelect={handleSelect}
              bookingData={bookingData}
              carList={carList}
              pickupLocationList={pickupLocationList}
              dropoffLocationList={dropoffLocationList}
              addOnList={addOnList}
              currency={currency}
              currencyRate={currencyRate}
              currencyLoading={currencyLoading}
              updateReservationInfo={updateReservationInfo}
              applyPromoCode={applyPromoCode}
              removePromoCode={removePromoCode}
            />)}
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }} className="StepperBuSec">
              <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }} className="TrendingBUBrown">Back</Button>
              {activeStep === 2 && (<Button type="submit" form="hook-form" className="TrendingBUGreen">SECURE PAYMENT</Button>)}
              {activeStep === 0 && (<Button onClick={handleNext} className="TrendingBUGreen" disabled={!(bookingData?.cars && bookingData?.cars?.length && !(bookingCars.indexOf(bookingData?.cars[0]?.car_id) > -1))}>Next</Button>)}
              {activeStep === 1 && (<Button onClick={handleNext} className="TrendingBUGreen">Next</Button>)}
            </Box>
          </React.Fragment>)}
        </Box>
      </div>
    </div>
    
    <Dialog
      maxWidth="sm"
      open={openSign}
      onClose={handleCloseSign}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <div className={`${styles.ImportantInfoSec}`}>
          <p className={`${styles.ImportantInfoTitle}`}>Please Signup to continue</p>
          <button className={`${styles.SignUpGuestBU}`} onClick={() => setOpenSign(false)}>Continue as a guest</button>
          <p className={`${styles.PopOrText}`}><span>or</span></p>
          <button className={`${styles.SignUpBUPopup}`} onClick={goToSignUp}>Signup</button>
        </div>
      </DialogContent>
    </Dialog>
  </React.Fragment>);
}