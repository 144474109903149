import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import styles from './header.module.css';
import { Menu as MenuIcon, User,ChevronDown,LogOut,Bookmark,Sliders } from 'react-feather';
import {authData} from "../../admin/components/getAuth";
import {Link as MUILink } from '@mui/material';
import {Menu, MenuItem} from '@mui/material';



function Header() {
  const auth = authData();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [currency, setCurrency] = React.useState('USD');
  const [currencyTitle, setCurrencyTitle] = React.useState('US Dollars');
  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  useEffect(()=>{
    let currentCurrency = localStorage.getItem("currentCurrency");
    if(!currentCurrency){
      localStorage.setItem('currentCurrency','USD');
      setCurrency('USD');
      setCurrencyTitle('US Dollars');
    }else{
      setCurrency(currentCurrency);
      if(currentCurrency === 'WST'){
        setCurrencyTitle('Samoan Tala');
      }
      if(currentCurrency === 'AUD'){
        setCurrencyTitle('AUS Dollars');
      }
      if(currentCurrency === 'NZD'){
        setCurrencyTitle('NZ Dollars');
      }
      if(currentCurrency === 'USD'){
        setCurrencyTitle('US Dollars');
      }
    }
  },[]);

  const cngCurrency = (item)=>{
    setAnchorEl2(null);
    localStorage.setItem('currentCurrency',item);
    setCurrency(item);
    if(item === 'WST'){
      setCurrencyTitle('Samoan Tala');
    }
    if(item === 'AUD'){
      setCurrencyTitle('AUS Dollars');
    }
    if(item === 'NZD'){
      setCurrencyTitle('NZ Dollars');
    }
    if(item === 'USD'){
      setCurrencyTitle('US Dollars');
    }
  }

  const logout=()=>{
    localStorage.removeItem('authData');
  }

  const goToReservation = ()=>{
    navigate('/reservation');
  }
  
    return (
      <header className={`${styles.HeaderMain}`}>
        <div className='container'>
          <div className={`${styles.HeaderRow}`}>
            <div className={`${styles.HeadLogo}`}><Link to={'/'}><img src="/images/moto-logo.svg" alt="" /></Link></div>
            <div className={`${styles.HeadMenu} ${showMobileMenu ? styles.Open : ''}`}>
              <ul>
                <li><Link to={'/'}>Home</Link></li>
                <li><Link to={'/reservation'}>Reservation</Link></li>
                {/* <li><Link to={'/car-services'}>Cars & Services</Link></li> */}
                <li><Link to={'/cars'}>Cars</Link></li>
                <li><Link to={'/services'}>Services</Link></li>
                {/*<li><Link to={'/book-ferry-ticket'}>Ferry Ticket</Link></li>*/}
                {/*<li><a href={'https://blog.motucarrentals.com/'} target="_blank" rel="noreferrer">Blog</a></li>*/}
                {auth ? <li><MUILink onClick={handleClick}><User /> <span className="UserName">{auth?.name}</span> <ChevronDown className={`${styles.Chev}`}/></MUILink></li>:<li><Link to={'/sign-up'}><User /> Sign Up</Link></li>}
                <li><MUILink onClick={handleClick2}>
                {(currency === 'WST') && <img src="/images/Flag-Samoa.svg" alt="" className={`${styles.MenuFlag}`} />}
                {(currency === 'AUD') && <img src="/images/Flag-Australia.svg" alt="" className={`${styles.MenuFlag}`} />}
                {(currency === 'NZD') && <img src="/images/Flag-NewZealand.svg" alt="" className={`${styles.MenuFlag}`} />}
                {(currency === 'USD') && <img src="/images/Flag-USA.svg" alt="" className={`${styles.MenuFlag}`} />}
                   {currencyTitle} <ChevronDown className={`${styles.Chev}`}/></MUILink></li>
              </ul>
              <Menu className={`${styles.UserDropMenu}`} id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{ 'aria-labelledby':  'basic-button', }}>
                {(auth?.role === 1) && <MenuItem onClick={handleClose}><Link to={'/admin/dashboard'} className={`${styles.UserProfile}`}><Sliders /> Dashboard</Link></MenuItem>}
                {(auth?.role === 2) && <MenuItem onClick={handleClose}><Link to={'/customer/my-bookings'} className={`${styles.UserProfile}`}><Bookmark /> My Bookings</Link></MenuItem>}
                {(auth?.role === 2) && <MenuItem onClick={handleClose}><Link to={'/customer/my-profile'} className={`${styles.UserProfile}`}><User /> My Profile</Link></MenuItem>}
                <MenuItem onClick={handleClose}><button onClick={logout.bind(this)} className={`${styles.UserLogoutBU}`}><LogOut/>Logout</button></MenuItem>
              </Menu>
              <Menu className={`${styles.UserDropMenu}`} id="basic-menu-2" anchorEl={anchorEl2} open={open2} onClose={handleClose2} MenuListProps={{ 'aria-labelledby':  'basic-menu-2', }}>
                {(currency !== 'WST') && <MenuItem className={`${styles.USDCurrency}`} onClick={cngCurrency.bind(this,'WST')} cngCurrency="1">WST - Samoan Tala</MenuItem>}
                {(currency !== 'AUD') && <MenuItem className={`${styles.USDCurrency}`} onClick={cngCurrency.bind(this,'AUD')} cngCurrency="1">AUD - Australian Dollars</MenuItem>}
                {(currency !== 'NZD') && <MenuItem className={`${styles.USDCurrency}`} onClick={cngCurrency.bind(this,'NZD')} cngCurrency="1">NZD - New Zealand Dollars</MenuItem>}
                {(currency !== 'USD') && <MenuItem className={`${styles.USDCurrency}`} onClick={cngCurrency.bind(this,'USD')} cngCurrency="1">USD - US Dollars</MenuItem>}
              </Menu>
              <button className={`${styles.HeaderBU}`} onClick={goToReservation}><span>Book Now</span></button>
            </div>
            <button onClick={() => setShowMobileMenu(v => !v)} className={`${styles.MenuHamBU}`}><MenuIcon/></button>
          </div>
        </div>
      </header>
  );
}


export default Header;