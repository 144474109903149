import React, { useState, useEffect } from "react";
import styles from "./add.module.css";
import { Typography, Breadcrumbs, Button } from "@mui/material";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { NumericFormat } from "react-number-format";
import { Upload, X } from "react-feather";
import { toast } from "react-smart-toaster";
import { FromLoader } from "../../components/LoaderC";
import Loader from "../../components/Loader";
import withAdminAuth from "../../components/withAdminAuth";
import { authData } from "../../components/getAuth";
import Select from "react-select";
import { Editor } from "@tinymce/tinymce-react";
const TypeOptions = [
  { value: "Recomended Addons", label: "Recomended Addons" },
  { value: "Primary Addons", label: "Primary Addons" },
  { value: "Insurance Service", label: "Insurance Service" },
  { value: "Beach Accessories", label: "Beach Accessories" },
  { value: "Tech Accessories", label: "Tech Accessories" },
];
const paymentTypeOptions = [
  { value: "Day Wise", label: "Day Wise" },
  { value: "One Time", label: "One Time" },
];

function AddOnsAdd({ history, match }) {
  const auth = authData();
  const audience = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  let { id } = useParams();
  const isAddMode = !id;
  const [ImgClass, setImgClass] = useState({
    progressBar: "hide",
    progress: 0,
    imageBlock: "hide",
    imagePathArr: "",
    imageNameArr: "",
  });
  const [ImgPathTemp, setImgPathTemp] = useState({
    Files: "",
    imagePathArr: "",
  });
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [editorVal, setEditorVal] = useState("");

  const schema = yup.object().shape({
    title: yup
      .string()
      .required("This field is Required")
      .max(50, "Name cannot be more than 50 characters"),
    price: yup.string().required("This field is Required"),
    quantity: yup.string(),
    type: yup.object().required("This field is Required"),
    payment_type: yup.object().required("This field is Required"),
    description: yup.string().required("This field is Required"),
    /*imagefile: yup.mixed().test("required", "Please upload a file", (file) => {
      if (ImgClass?.imagePathArr !== '') return true;
      if (ImgPathTemp?.imagePathArr !== '') return true;
      return false;
    })*/
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  useEffect(() => {
    if (!isAddMode) {
      if (contentLoading) {
        axios
          .get(audience + "v1/add-ons/" + id, {
            headers: {
              Authorization: `Bearer ${auth?.api_token}`,
            },
          })
          .then((response) => {
            setContentLoading(false);
            let itemData = response.data.data;
            setValue("title", itemData.title);
            setValue("type", { value: itemData.type, label: itemData.type });
            setValue("payment_type", {
              value: itemData.payment_type,
              label: itemData.payment_type,
            });
            setValue("price", itemData.price);
            setValue("quantity", itemData.quantity);
            setValue("description", itemData.description);
            setEditorVal(itemData.description);

            if (itemData.image_url !== "") {
              setImgClass({ ...ImgClass, imagePathArr: itemData.image_url });
            }
          })
          .catch((error) => {
            setContentLoading(false);
          });
      }
    } else {
      setContentLoading(false);
    }
  }, [isAddMode, audience, id, setValue, ImgClass, contentLoading, auth]);

  /****Image Upload****/
  const fileHandler = (e) => {
    var validExtensions = ["jpg", "jpeg", "png"]; //array of valid extensions
    var fileName = e.target.files[0].name;
    var fileNameExt = fileName.substr(fileName.lastIndexOf(".") + 1);
    if (validExtensions.indexOf(fileNameExt.toLowerCase()) === -1) {
      toast.error("File type is not allowed");
    } else if (e.target.files[0].size > 5242880) {
      toast.error("Image size should not be greater than 5 MB.");
    } else {
      let tempFiles = ImgPathTemp.Files;
      let tempImages = ImgPathTemp.imagePathArr;
      let file = e.target.files[0];
      tempFiles = file;
      tempImages = URL.createObjectURL(file);
      setImgPathTemp({ imagePathArr: tempImages, Files: tempFiles });

      deleteImage();
    }
  };

  function deleteTempImage() {
    setValue("imagefile", null);
    setImgPathTemp({ imagePathArr: "", Files: "" });
  }

  function deleteImage() {
    setImgClass({ ...ImgClass, imagePathArr: "", imageNameArr: "" });
  }

  function fileUploadButtonClickTemp() {
    document
      .getElementById("fileUploadID")
      .getElementsByClassName("inputBtn")[0]
      .click();
    return false;
  }

  async function onSubmit(data) {
    data = {
      ...data,
      type: data?.type?.value,
      payment_type: data?.payment_type?.value,
    };
    let formData = new FormData();
    Object.keys(data).map((key) => {
      if (key !== "imagefile" && data[key]) {
        formData.append(key, data[key]);
      }
      return true;
    });

    if (ImgPathTemp.Files) {
      formData.append("image", ImgPathTemp.Files);
    }
    const postData = formData;
    setLoading(true);

    if (isAddMode) {
      return axios
        .post(audience + "v1/add-ons", postData, {
          headers: {
            Authorization: `Bearer ${auth?.api_token}`,
          },
        })
        .then((response) => {
          setLoading(false);
          toast.success(response.data.message);
          navigate("/admin/add-on/list");
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      return axios
        .post(audience + "v1/add-ons/" + id, postData, {
          headers: {
            Authorization: `Bearer ${auth?.api_token}`,
          },
        })
        .then((response) => {
          setLoading(false);
          toast.success(response.data.message);
          navigate("/admin/add-on/list");
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }

  const TypeField = React.forwardRef((field, ref) => {
    return (
      <Controller
        control={control}
        options={TypeOptions}
        {...register("type")}
        render={({ field }) => <Select {...field} options={TypeOptions} />}
      />
    );
  });
  const PaymentTypeField = React.forwardRef((field, ref) => {
    return (
      <Controller
        control={control}
        options={paymentTypeOptions}
        {...register("payment_type")}
        render={({ field }) => (
          <Select {...field} options={paymentTypeOptions} />
        )}
      />
    );
  });
  const handleEditorChange = (content, editor) => {
    setEditorVal(content);
    setValue("description", content);
  };
  return (
    <React.Fragment>
      {loading && <Loader />}
      {contentLoading && (
        <div className="LoaderCard">
          <div className="Loader FromLoader">
            <FromLoader />
          </div>
        </div>
      )}
      {!contentLoading && (
        <div className={`${styles.MainDiv}`}>
          <div className={`${styles.PageTitleWrap}`}>
            <p className={`${styles.PageTitle}`}>
              AddOns {isAddMode ? "Add" : "Edit"}
            </p>
            <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
              <Link underline="hover" color="inherit" to="/admin/dashboard">
                Dashboard
              </Link>
              <Link underline="hover" color="inherit" to={"/admin/add-on/list"}>
                AddOns
              </Link>
              <Typography color="text.primary">
                {isAddMode ? "Add" : "Edit"}
              </Typography>
            </Breadcrumbs>
          </div>

          <div className={`${styles.MainCard}`}>
            <div className={`${styles.AddUserMain}`}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>Title*</label>
                    <input
                      {...register("title")}
                      className={`${styles.FormControl}`}
                    />
                    {errors.title && !watch().title && (
                      <span className={`${styles.ErrorM}`}>
                        {errors.title.message}
                      </span>
                    )}
                  </div>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>Price(WST)*</label>
                    <Controller
                      control={control}
                      name="price"
                      className={`${styles.FormControl}`}
                      render={({ field: { onChange, name, value } }) => (
                        <NumericFormat
                          decimalScale={2}
                          name={name}
                          value={value}
                          onChange={onChange}
                          className={`${styles.FormControl}`}
                        />
                      )}
                    />
                    {errors.price && (
                      <span className={`${styles.ErrorM}`}>
                        {errors.price.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroupFull}`}>
                    <label className={`${styles.FormLabel}`}>
                      Description*
                    </label>
                    {/* <textarea {...register("description")} className={`${styles.FormControlTextArea}`} rows="5"></textarea> */}
                    <input
                      type="hidden"
                      {...register("description")}
                      className={`${styles.FormControl}`}
                    />
                    <Editor
                      onEditorChange={handleEditorChange}
                      value={editorVal}
                      apiKey="esnq02ea10s4qrcjx6m5z9h03c3iln9jfml86ysvw8legkpo"
                      init={{
                        height: 400,
                        menubar: false,
                        plugins:
                          "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                        toolbar:
                          "undo redo | bold italic underline strikethrough | fontselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat |  fullscreen  | image media link | ltr rtl | code",
                      }}
                    />
                    {errors.description && !watch().description && (
                      <span className={`${styles.ErrorM}`}>
                        {errors.description.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroup}`} style={{zIndex: 9999}}>
                    <label className={`${styles.FormLabel}`}>Type*</label>
                    <TypeField />
                    {errors.type && !watch().type && (
                      <span className={`${styles.ErrorM}`}>
                        {errors.type.message}
                      </span>
                    )}
                  </div>

                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>Quantity*</label>
                    <Controller
                      control={control}
                      name="quantity"
                      className={`${styles.FormControl}`}
                      render={({ field: { onChange, name, value } }) => (
                        <NumericFormat
                          isAllowed={(values, sourceInfo) => {
                            const { value } = values;
                            return value < 1000;
                          }}
                          name={name}
                          value={value}
                          onChange={onChange}
                          className={`${styles.FormControl}`}
                        />
                      )}
                    />

                    {errors.quantity && (
                      <span className={`${styles.ErrorM}`}>
                        {errors.quantity.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroup}`} style={{zIndex: 8888}}>
                    <label className={`${styles.FormLabel}`}>
                      Payment Type*
                    </label>
                    <PaymentTypeField />
                    {errors.payment_type && !watch().payment_type && (
                      <span className={`${styles.ErrorM}`}>
                        {errors.payment_type.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroupFull}`}>
                    <label className={`${styles.FormLabel}`}>Image*</label>
                    <div className={`${styles.FileIMGDiv}`}>
                      <div className={`${styles.FileUpDuBUDiv}`}>
                        <label
                          htmlFor="file-upload"
                          className={`${styles.UploadLabel}`}
                          id="fileUploadID"
                        >
                          <input
                            type="file"
                            className="inputBtn"
                            {...register("imagefile")}
                            onChange={fileHandler}
                            accept=".jpg,.png,.jpeg"
                            inputprops={{ accept: "image/*" }}
                          />
                          <span onClick={fileUploadButtonClickTemp.bind()}>
                            <div className={`${styles.UpIcon}`}>
                              <Upload />
                            </div>{" "}
                            <p>Upload Photo</p>
                          </span>
                          <p className={`${styles.UploadText}`}>
                            File size upto 100 KB
                          </p>
                          <p className={`${styles.UploadText2}`}>
                            JPEG, JPG, PNG
                          </p>
                        </label>

                        <span
                          className={
                            "e-upload-progress-wrap " + ImgClass.progressBar
                          }
                        >
                          <span className="e-progress-inner-wrap">
                            {/* <progressbar className="e-upload-progress-bar e-upload-progress" value="0" max="100" style={{ width: ImgClass.progress + '%' }}></progressbar> */}
                          </span>
                          <span className="e-progress-bar-text">
                            {ImgClass.progress}%
                          </span>
                        </span>
                      </div>
                      {ImgClass.imagePathArr && (
                        <div className={`${styles.ImgFile}`}>
                          <img src={ImgClass.imagePathArr} alt="" />
                          <Button onClick={deleteImage.bind(this)}>
                            <X />
                          </Button>
                        </div>
                      )}
                      {ImgPathTemp.imagePathArr &&
                        ImgPathTemp.imagePathArr !== "" && (
                          <div className={`${styles.ImgFile}`}>
                            <img src={ImgPathTemp.imagePathArr} alt="" />
                            <Button onClick={deleteTempImage.bind(this)}>
                              <X />
                            </Button>
                          </div>
                        )}
                    </div>
                    {errors.imagefile && (
                      <p className={`${styles.ErrorM}`}>
                        {errors.imagefile.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className={`${styles.FormButtonArea}`}>
                  {/* <button className={`${styles.CancelBU}`} type="button">Cancel</button> */}
                  <Link
                    to={`/admin/add-on/list`}
                    as={Button}
                    className={`${styles.CancelBU}`}
                  >
                    Cancel
                  </Link>
                  <input
                    className={`${styles.SubmitBU}`}
                    value="Submit"
                    type="submit"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default withAdminAuth(AddOnsAdd);
