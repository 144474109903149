import React from "react";
import styles from "../reservation.module.css";
import { NumericFormat, PatternFormat } from "react-number-format";
import { Controller, useForm } from "react-hook-form";
import { Link } from "@mui/material";
import LeftPanelBlock from "./LeftPanelBlock";
import { useNavigate } from "react-router-dom";
import { authData } from "../../../../admin/components/getAuth";

const Step3 = ({handleSelect, bookingData, carList, pickupLocationList, dropoffLocationList, addOnList, saveUserData, currency, currencyRate, currencyLoading, updateReservationInfo}) => {
    const auth = authData();
    const {register, handleSubmit, control, formState: { errors }} = useForm({
        defaultValues: {
            first_name: bookingData?.userData ? bookingData?.userData?.first_name : auth ? auth?.customer_details?.first_name : "",
            last_name: bookingData?.userData ? bookingData?.userData?.last_name : auth ? auth?.customer_details?.last_name : "",
            phone: bookingData?.userData ? bookingData?.userData?.phone : auth ? auth?.customer_details?.phone : "",
            email: bookingData?.userData ? bookingData?.userData?.email : auth ? auth?.customer_details?.email : "",
            address: bookingData?.userData ? bookingData?.userData?.address : auth ? auth?.customer_details?.address : "",
            city: bookingData?.userData ? bookingData?.userData?.city : auth ? auth?.customer_details?.city : "",
            state: bookingData?.userData ? bookingData?.userData?.state : auth ? auth?.customer_details?.state : "",
            zip: bookingData?.userData ? bookingData?.userData?.zip : auth ? auth?.customer_details?.zipcode : "",
            age: bookingData?.userData ? bookingData?.userData?.age : auth ? auth?.customer_details?.age : "",
            no_of_people: bookingData?.userData ? bookingData?.userData?.no_of_people : ""
        }
    });
    const navigate = useNavigate();
    
    const onSubmit = (data) => {
        saveUserData(data);
    }
    
    const goToLogin = () => {
        localStorage.setItem("reDirectUrl", "/reservation");
        navigate("/sign-in");
    }
    
    return (<React.Fragment>
        <div className={`${styles.SelectCarSec}`}>
            <LeftPanelBlock
                handleSelect={handleSelect}
                bookingData={bookingData}
                carList={carList}
                pickupLocationList={pickupLocationList}
                dropoffLocationList={dropoffLocationList}
                addOnList={addOnList}
                currency={currency}
                currencyRate={currencyRate}
                currencyLoading={currencyLoading}
                currentStep={3}
                updateReservationInfo={updateReservationInfo}
            />
            
            <div className={`${styles.AddonsBigSide}`}>
                <form id="hook-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className={`${styles.ClientDetailsForm}`}>
                        <div className={`${styles.FormHead}`}>
                            <h2 className={`${styles.BookingFormTitle}`}>Customer Information</h2>
                            {auth ? (<p className={`${styles.LoginFormSTextTwo}`}>Logged in as <Link>{auth?.name}</Link></p>) : (<p className={`${styles.LoginFormSTextTwo}`}>Already have an account?{" "}<Link onClick={goToLogin}>LogIn</Link></p>)}
                        </div>
                        <div className={`${styles.FormRow}`}>
                            <div className={`${styles.FormGroup}`}>
                                <label class={`${styles.FormLabel}`}>First Name*</label>
                                <input type="text" {...register("first_name", { required: true })} className={`${styles.FormControl}`} />
                                {errors?.first_name?.type === "required" && (<span className={`${styles.ErrorM}`}>This field is required</span>)}
                            </div>
                            <div className={`${styles.FormGroup}`}>
                                <label class={`${styles.FormLabel}`}>Last Name*</label>
                                <input type="text" {...register("last_name", { required: true })} className={`${styles.FormControl}`} />
                                {errors?.last_name?.type === "required" && (<span className={`${styles.ErrorM}`}>This field is required</span>)}
                            </div>
                        </div>
                        <div className={`${styles.FormRow}`}>
                            <div className={`${styles.FormGroup}`}>
                                <label class={`${styles.FormLabel}`}>Phone Number*</label>
                                <Controller
                                    control={control}
                                    name="phone"
                                    {...register("phone", { required: true })}
                                    className={`${styles.FormControl}`}
                                    render={({ field: { onChange, name, value } }) => (<PatternFormat
                                        format="+############"
                                        name={name}
                                        value={value}
                                        onChange={onChange}
                                        className={`${styles.FormControl}`}
                                    />)}
                                />
                                {errors?.phone?.type === "required" && (<span className={`${styles.ErrorM}`}>This field is required</span>)}
                            </div>
                            <div className={`${styles.FormGroup}`}>
                                <label class={`${styles.FormLabel}`}>Email*</label>
                                <input type="email" {...register("email", {
                                    required: "This field is required",
                                    pattern: {
                                        value:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: "Invalid email address"
                                    }
                                })} className={`${styles.FormControl}`} readOnly={auth && auth?.role === 2} />
                                {errors?.email && (<span className={`${styles.ErrorM}`}>{errors.email?.message}</span>)}
                            </div>
                        </div>
                        <div className={`${styles.FormRow}`}>
                            <div className={`${styles.FormGroupFull}`}>
                                <label class={`${styles.FormLabel}`}>Address*</label>
                                <input type="text" {...register("address", { required: true })} className={`${styles.FormControl}`} />
                                {errors?.address?.type === "required" && (<span className={`${styles.ErrorM}`}>This field is required</span>)}
                            </div>
                        </div>
                        <div className={`${styles.FormRow}`}>
                            <div className={`${styles.FormGroupOneThird}`}>
                                <label class={`${styles.FormLabel}`}>Country/State*</label>
                                <input type="text" {...register("state", { required: true })} className={`${styles.FormControl}`} />
                                {errors?.state?.type === "required" && (<span className={`${styles.ErrorM}`}>This field is required</span>)}
                            </div>
                            <div className={`${styles.FormGroupOneThird}`}>
                                <label class={`${styles.FormLabel}`}>City*</label>
                                <input type="text" {...register("city", { required: true })} className={`${styles.FormControl}`} />
                                {errors?.city?.type === "required" && (<span className={`${styles.ErrorM}`}>This field is required</span>)}
                            </div>
                            <div className={`${styles.FormGroupOneThird}`}>
                                <label class={`${styles.FormLabel}`}>Zip/Postal Code*</label>
                                <Controller
                                    control={control}
                                    ame="zip"
                                    {...register("zip", { required: true })}
                                    className={`${styles.FormControl}`}
                                    render={({ field: { onChange, name, value } }) => (<PatternFormat
                                        format="######"
                                        name={name}
                                        value={value}
                                        onChange={onChange}
                                        className={`${styles.FormControl}`}
                                    />)}
                                />
                                {errors?.zip?.type === "required" && (<span className={`${styles.ErrorM}`}>This field is required</span>)}
                            </div>
                        </div>
                        <div className={`${styles.FormRow}`}>
                            <div className={`${styles.FormGroup}`}>
                                <label class={`${styles.FormLabel}`}>Number of Passenger(s)*</label>
                                <Controller
                                    control={control}
                                    name="no_of_people"
                                    {...register("no_of_people", { required: true, min: 0 })}
                                    className={`${styles.FormControl}`}
                                    render={({ field: { onChange, name, value } }) => (<NumericFormat
                                        isAllowed={(values, sourceInfo) => {
                                            const { value } = values;
                                            return value < 100;
                                        }}
                                        name={name}
                                        value={value}
                                        onChange={onChange}
                                        className={`${styles.FormControl}`}
                                    />)}
                                />
                                {errors?.no_of_people?.type === "required" && (<span className={`${styles.ErrorM}`}>This field is required</span>)}
                                {errors?.no_of_people?.type === "min" && (<span className={`${styles.ErrorM}`}>Number of Passenger(s) should be grater than 0</span>)}
                            </div>
                            <div className={`${styles.FormGroup}`}>
                                <label class={`${styles.FormLabel}`}>Driver's Age*</label>
                                <Controller
                                    control={control}
                                    name="age"
                                    {...register("age", { required: true, min: 25 })}
                                    className={`${styles.FormControl}`}
                                    render={({ field: { onChange, name, value } }) => (<NumericFormat
                                        isAllowed={(values, sourceInfo) => {
                                            const { value } = values;
                                            return value < 100;
                                        }}
                                        name={name}
                                        alue={value}
                                        onChange={onChange}
                                        className={`${styles.FormControl}`}
                                    />)}
                                />
                                {errors?.age?.type === "required" && (<span className={`${styles.ErrorM}`}>This field is required</span>)}
                                {errors?.age?.type === "min" && (<span className={`${styles.ErrorM}`}>Driver's Age should be grater than 24</span>)}
                            </div>
                        </div>
                        <div className={`${styles.FormRow}`}>
                            <div className={`${styles.FormGroup}`}>
                                <label class={`${styles.FormLabel}`}>Arrival Airline Carrier*</label>
                                <input type="text" {...register("airline_carrier", { required: true })} className={`${styles.FormControl}`} />
                                {errors?.airline_carrier?.type === "required" && (<span className={`${styles.ErrorM}`}>This field is required</span>)}
                            </div>
                            <div className={`${styles.FormGroup}`}>
                                <label class={`${styles.FormLabel}`}>Arrival Vessel Number*</label>
                                <input type="text" {...register("vessel_number", { required: true })} className={`${styles.FormControl}`} />
                                {errors?.vessel_number?.type === "required" && (<span className={`${styles.ErrorM}`}>This field is required</span>)}
                            </div>
                        </div>
                        <div className={`${styles.FormRow}`}>
                            <div className={`${styles.FormGroup}`}>
                                <label class={`${styles.FormLabel}`}>Departure Airline Carrier*</label>
                                <input type="text" {...register("departure_airline_carrier", {required: true})} className={`${styles.FormControl}`} />
                                {errors?.departure_airline_carrier?.type === "required" && (<span className={`${styles.ErrorM}`}>This field is required</span>)}
                            </div>
                            <div className={`${styles.FormGroup}`}>
                                <label class={`${styles.FormLabel}`}>Departure Vessel Number*</label>
                                <input type="text" {...register("departure_vessel_number", { required: true })} className={`${styles.FormControl}`} />
                                {errors?.departure_vessel_number?.type === "required" && (<span className={`${styles.ErrorM}`}>This field is required</span>)}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </React.Fragment>);
}

export default Step3;