import React from 'react';
import styles from './aboutus.module.css';
import { Parallax } from 'react-parallax';
import Fade from 'react-reveal/Fade';
import axios from 'axios';
// import { Link } from 'react-router-dom';
class AboutUs extends React.Component {
  constructor(props){
    super(props);
    this.state={
      contentList:[]
    };
    this.props=props;
  }
  componentWillMount(){

    axios(process.env.REACT_APP_API_URL + 'v1/page-manager/about-us').then(response => {
     
      let itemData = response.data.data;
       itemData = JSON.parse(itemData.description);
    
      this.setState({...this.state, contentList: itemData});
    }).catch(error=>{
      this.setState({...this.state, contentList: []});
    });

  }

  render() {
    return (
      <React.Fragment>
        <div className="InnerBanner">
          <Parallax bgImage="/images/aboutus-banner.jpg" strength={400}>
            <div style={{ height: 450 }} className={`${styles.BannerConArea}`}>
              <div className='container'>
                <div className={`${styles.InnerBannerRow}`}>
                  <h2 className={`${styles.InnerBannerTitle}`}>About Us</h2>
                </div>
              </div>
            </div>
          </Parallax>
        </div>

        <div className={`${styles.AboutSec}`}>
          <Fade bottom>
            <div className="container">
              <div className={`${styles.AboutSecRow}`}>
                <div className={`${styles.AboutSecBig}`}>
                  <h3 className={`${styles.AboutTitle}`}>{this.state.contentList.story_title}</h3>
                  {/* <p className={`${styles.AboutText}`}>{this.state.contentList.story_description}</p> */}
                  <p class={`${styles.AboutText}`} dangerouslySetInnerHTML={{__html: this.state.contentList.story_description}}></p>
                </div>
                <div className={`${styles.AboutSecSmall}`}>
                  <img src="/images/aboutUsImg.png" alt="" className={`${styles.AboutImg}`} />
                </div>
              </div>
            </div>
          </Fade>
        </div>

        <div className={`${styles.BodyMessagesArea}`}>
          <img src="/images/CarBG.png" alt="" className={`${styles.CarBG}`} />
          <div className='container'>
            <div className={`${styles.BodyMRow}`}>

              <Fade bottom>

                <div className={`${styles.BodyFull}`}>
                  <h3 class={`${styles.WhyChooseTitleM}`}>Why Book with Us?</h3>      
                  <div className={`${styles.WhyChooseRow}`}>

                    <div className={`${styles.WhyChooseDiv}`}>
                      <div className={`${styles.WhyChooseUnder}`}>
                        <img src="/images/why_choose_us_img1.svg" alt='' />
                        <h4 className={`${styles.WhyChooseTitle}`}>Pay online</h4>
                        <p className={`${styles.WhyChooseText}`}>Book a car, pay online, and receive a confirmation in real-time.</p>
                        <img src="/images/car_steering.svg" className={`${styles.WhyBGGra}`} alt="" />
                      </div>
                    </div>

                    <div className={`${styles.WhyChooseDiv}`}>
                      <div className={`${styles.WhyChooseUnder}`}>
                        <img src="/images/why_choose_us_img2.svg" alt='' />
                        <h4 className={`${styles.WhyChooseTitle}`}>Insured</h4>
                        <p className={`${styles.WhyChooseText}`}>All of the vehicles in our fleet have premium insurance.</p>
                        <img src="/images/car_steering.svg" className={`${styles.WhyBGGra}`} alt="" />
                      </div>
                    </div>

                    <div className={`${styles.WhyChooseDiv}`}>
                      <div className={`${styles.WhyChooseUnder}`}>
                        <img src="/images/why_choose_us_img3.svg" alt='' />
                        <h4 className={`${styles.WhyChooseTitle}`}>Add-Ons</h4>
                        <p className={`${styles.WhyChooseText}`}>Convenience at the click of a button. Choose from our extensive inventory to enhance your trip experience.</p>
                        <img src="/images/car_steering.svg" className={`${styles.WhyBGGra}`} alt="" />
                      </div>
                    </div>

                  </div>
                </div>

              </Fade>

            </div>
          </div>
        </div>

        <div className={`${styles.InsuranceSec}`}>
          <img src="/images/waveInsur.svg" alt="" className={`${styles.waveInsur}`} />
          <Fade bottom>
          <div className='container'>
            <div className={`${styles.AboutMissionVissionSec}`}>
              <div className={`${styles.HalfSecMVS}`}>
                <h3 class={`${styles.InsurTitle}`}>{this.state.contentList.mission_title}</h3>
                <p class={`${styles.InsurSmallTitle}`} dangerouslySetInnerHTML={{__html: this.state.contentList.mission_description}}></p>
              </div>
              <div className={`${styles.HalfSecMVS}`}>
                <h3 class={`${styles.InsurTitle}`}>{this.state.contentList.vission_title}</h3>
              
                <p class={`${styles.InsurSmallTitle}`} dangerouslySetInnerHTML={{__html: this.state.contentList.vission_description}}></p>
              </div>
            </div>
          </div>
          </Fade>
        </div>
      </React.Fragment>
  )}
}

export default AboutUs;