import React from "react";
import styles from "./list.module.css";
import { Link } from "react-router-dom";
import { Typography, Breadcrumbs, TextField, Button } from "@mui/material";
import DataTable from "react-data-table-component";
import { Plus, X, Edit, Trash2, ChevronDown } from "react-feather";
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import { TableLoader } from "../../components/LoaderC";
import Loader from "../../components/Loader";
import { toast } from "react-smart-toaster";
import withAdminAuth from "../../components/withAdminAuth";
import { authData } from "../../components/getAuth";

class AddOnsList extends React.Component {
  constructor({ match, ...props }) {
    super(...arguments);
    this.state = {
      allData: [],
      data: [],
      filterText: "",
      modalShow: false,
      loading: false,
      contentLoading: true,
      currentItem: 0,
    };

    this.audience = process.env.REACT_APP_API_URL;
    this.app_folder_path = process.env.REACT_APP_FOLDER_PATH;
    this.authData = authData();

    this.columns = [
      {
        name: "Title",
        selector: (row) => row.title,
        sortable: true,
      },
      {
        name: "Type",
        selector: (row) => row.type,
        sortable: true,
      },
      {
        name: "Payment Type",
        selector: (row) => row.payment_type,
        sortable: true,
      },
      {
        name: "Price(WST)",
        selector: (row) => row.price,
        sortable: true,
      },
      {
        name: "Quantity",
        selector: (row) => row.quantity,
        sortable: true,
      },
      {
        name: "Image",
        cell: (tableProps) =>
          tableProps.image_url !== "" ? (
            <div>
              <img
                src={tableProps.image_url}
                alt="Add Ons"
                style={{ maxWidth: "100px", maxHeight: "100px" }}
              />
            </div>
          ) : null,
        sortable: false,
      },
      {
        name: "Status",
        cell: (tableProps) =>
          tableProps.status === 1 || tableProps.status === "1" ? (
            <Dropdown>
              <Dropdown.Toggle
                className={`${styles.ActiveBU}`}
                id={"dropdown-basic" + tableProps.id}
                size="sm"
              >
                Active <ChevronDown />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={this.statusChange.bind(this, tableProps.id, 0)}
                >
                  Archived
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <Dropdown>
              <Dropdown.Toggle
                className={`${styles.ArchiveBU}`}
                id={"dropdown-basic" + tableProps.id}
                size="sm"
              >
                Archived <ChevronDown />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={this.statusChange.bind(this, tableProps.id, 1)}
                >
                  Active
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ),
        sortable: false,
      },
      {
        name: "Action",
        cell: (tableProps) => (
          <div className={`${styles.ActionDiv}`}>
            <Link to={"/admin/add-on/edit/" + tableProps.id}>
              <Edit />
            </Link>
            <Button onClick={this.modalOpen.bind(this, tableProps.id)}>
              <Trash2 />
            </Button>
          </div>
        ),
        sortable: false,
      },
    ];
  }

  async componentDidMount() {
    try {
      axios(this.audience + "v1/add-ons", {
        headers: {
          Authorization: `Bearer ${this.authData?.api_token}`,
        },
      })
        .then((response) => {
          let allData = response.data.data;

          this.setState({
            ...this.state,
            allData: allData,
            data: allData,
            contentLoading: false,
          });
        })
        .catch((error) => {
          this.setState({ ...this.state, contentLoading: false });
        });
    } catch (error) {
      this.setState({ ...this.state, contentLoading: false });
    }
  }
  onFilter(e) {
    this.setState(
      { ...this.state, filterText: e.target.value },
      this.filterHandler
    );
  }
  clearFilter() {
    this.setState({ ...this.state, filterText: "" }, this.filterHandler);
  }
  filterHandler() {
    let filteredData = this.state.allData;

    if (this.state.filterText !== "") {
      let inputVal = this.state.filterText;
      filteredData = filteredData.filter((item) => {
        return item.title.toLowerCase().includes(inputVal);
      });
    }

    this.setState({ ...this.state, data: filteredData });
  }

  statusChange(id, status) {
    this.setState({ ...this.state, loading: true });
    axios
      .put(
        this.audience + "v1/add-ons/" + id,
        { status: status },
        {
          headers: {
            Authorization: `Bearer ${this.authData?.api_token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        let allData = this.state.allData;
        let data = this.state.data;
        let allDataTemp = allData.map((i) => {
          if (i.id === id) return { ...i, status: status };
          return i;
        });
        let dataTemp = data.map((i) => {
          if (i.id === id) return { ...i, status: status };
          return i;
        });
        this.setState({
          ...this.state,
          allData: allDataTemp,
          data: dataTemp,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ ...this.state, loading: false });
      });
  }

  modalOpen(id) {
    this.setState({ ...this.state, modalShow: true, currentItem: id });
  }

  modalClose() {
    this.setState({ ...this.state, modalShow: false, currentItem: 0 });
  }

  deleteConfirm() {
    let currentItem = this.state.currentItem;
    this.setState({
      ...this.state,
      modalShow: false,
      currentItem: 0,
      loading: true,
    });
    axios
      .delete(this.audience + "v1/add-ons/" + currentItem, {
        headers: {
          Authorization: `Bearer ${this.authData?.api_token}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        let allData = this.state.allData;
        let data = this.state.data;
        let allDataTemp = allData.filter((i) => i.id !== currentItem);
        let dataTemp = data.filter((i) => i.id !== currentItem);
        this.setState({
          ...this.state,
          allData: allDataTemp,
          data: dataTemp,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ ...this.state, loading: false });
      });
  }

  render() {
    return (
      <>
        {this.state.loading && <Loader />}
        {this.state.contentLoading && (
          <div className="LoaderCard">
            <div className="Loader TableLoader">
              <TableLoader />
            </div>
          </div>
        )}
        {!this.state.contentLoading && (
          <div className={`${styles.MainDiv}`}>
            <div className={`${styles.PageTitleWrap}`}>
              <p className={`${styles.PageTitle}`}>AddOns List</p>
              <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
                <Link color="inherit" to="/admin/dashboard">
                  Dashboard
                </Link>
                <Link color="inherit" to={"/admin/add-on/list"}>
                  AddOns
                </Link>
                <Typography color="text.primary">List</Typography>
              </Breadcrumbs>
            </div>

            <div className={`${styles.MainCard}`}>
              <div className="TableFilterHead">
                <div className="DataTableSearch">
                  <TextField
                    id="search"
                    type="text"
                    placeholder="Search"
                    aria-label="Search Input"
                    value={this.state.filterText}
                    onChange={this.onFilter.bind(this)}
                  />
                  {this.state.filterText && (
                    <Button
                      type="button"
                      className="CloseBU"
                      onClick={this.clearFilter.bind(this)}
                    >
                      <X />
                    </Button>
                  )}
                </div>
                <div className="TableFilterBUSec">
                  <Link to="/admin/add-on/add" className="TableAddBU">
                    <Plus />
                  </Link>
                </div>
              </div>

              <div className="TableContent">
                <DataTable
                  className="DataTable"
                  columns={this.columns}
                  data={this.state.data}
                  pagination
                />
              </div>
            </div>
          </div>
        )}

        <Modal
          show={this.state.modalShow}
          onHide={this.modalClose.bind(this)}
          centered
          className="CusModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="Message">
              Are you sure you want to delete this item?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button className="PopUpCanBU" onClick={this.modalClose.bind(this)}>
              Cancel
            </Button>
            <Button
              className="PopUpSubmitBU"
              onClick={this.deleteConfirm.bind(this)}
            >
              Confirm Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default withAdminAuth(AddOnsList);
