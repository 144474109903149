import React, { useState, useEffect } from "react";
import styles from "./add.module.css";
import { Typography, Breadcrumbs, Button, Checkbox } from "@mui/material";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { NumericFormat } from "react-number-format";
import { toast } from "react-smart-toaster";
import { FromLoader } from "../../components/LoaderC";
import Loader from "../../components/Loader";
import withAdminAuth from "../../components/withAdminAuth";
import { authData } from "../../components/getAuth";
import moment from "moment/moment";

const timeSlots = () =>{
  let slots = [];

  let cDate = moment().format('YYYY-MM-DD');
  let momentObj = cDate + ' 06:00:00';

  for(let i=0; i <= 10; i++){
    slots.push({label: moment(momentObj).add(i,'hour').format('hh:mm A'), value: moment(momentObj).add(i,'hour').format('HH:mm:ss')});
  }
  
  return slots;
}

function FerryTicketAdd({ history, match }) {
  const auth = authData();
  const audience = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  let { id } = useParams();
  const isAddMode = !id;
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);

  const schema = yup.object().shape({
    name: yup.string().required("This field is Required").max(100, "Name cannot be more than 100 characters"),
    amount: yup.string().required("This field is Required"),
  });

  const {register, handleSubmit, control, setValue, formState: { errors }, watch, getValues} = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues:{
      available_days: [{'label': 'Sunday'},{'label': 'Monday'},{'label': 'Tuesday'},{'label': 'Wednesday'},{'label': 'Thursday'},{'label': 'Friday'},{'label': 'Saturday'}]
    }
  });

  const { fields } = useFieldArray({ control, name: "available_days" });

  useEffect(() => {
    if(!isAddMode){
      if(contentLoading){
        axios.get(audience + "v1/ferry-tickets/" + id, {
          headers: {
            Authorization: `Bearer ${auth?.api_token}`
          }
        }).then((response) => {
          setContentLoading(false);
          let itemData = response.data.data;

          console.log(itemData);

          setValue("name", itemData.name);
          setValue("amount", itemData.amount);

          let available_days = itemData.available_days;

          console.log(timeSlots());
          console.log(available_days);

          available_days = available_days?.map((item,index)=>{
            console.log(item);
            let weekName;
            if(index === 1){
              weekName = 'Monday';
            }else if(index === 2){
              weekName = 'Tuesday';
            }else if(index === 3){
              weekName = 'Wednesday';
            }else if(index === 4){
              weekName = 'Thursday';
            }else if(index === 5){
              weekName = 'Friday';
            }else if(index === 6){
              weekName = 'Saturday';
            }else{
              weekName = 'Sunday';
            }
            let slots = timeSlots()?.map(subItem=>{
              if(item.indexOf(subItem?.value) > -1)
                return subItem?.value;
              return false;
            });
            return {'label':weekName, 'slots': slots};
          });

          setValue("available_days", available_days);

          console.log(available_days);
        }).catch((error) => {
          setContentLoading(false);
        });
      }
    } else {
      setContentLoading(false);
    }
  }, [isAddMode, audience, id, setValue, contentLoading, auth]);

  async function onSubmit(data) {
    let available_days = [];

    for(let i in data?.available_days){
      let aDays = data?.available_days[i];

      let slots = aDays?.slots?.filter(i => i !== false).map(i => i);
      available_days.push(slots);
    }
    
    data = { ...data, available_days: available_days };

    setLoading(true);

    if (isAddMode) {
      return axios.post(audience + "v1/ferry-tickets", data, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`
        }
      }).then((response) => {
        setLoading(false);
        toast.success(response.data.message);
        navigate("/admin/ferry-ticket/list");
      }).catch((error) => {
        setLoading(false);
      });
    } else {
      return axios.post(audience + "v1/ferry-tickets/" + id, data, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`
        }
      }).then((response) => {
        setLoading(false);
        toast.success(response.data.message);
        navigate("/admin/ferry-ticket/list");
      }).catch((error) => {
        setLoading(false);
      });
    }
  }
  
  return (<React.Fragment>
    {loading && <Loader />}
    
    {contentLoading && (<div className="LoaderCard">
      <div className="Loader FromLoader"><FromLoader /></div>
    </div>)}
    
    {!contentLoading && (<div className={`${styles.MainDiv}`}>
      <div className={`${styles.PageTitleWrap}`}>
        <p className={`${styles.PageTitle}`}>Ferry Ticket {isAddMode ? "Add" : "Edit"}</p>
        <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
          <Link underline="hover" color="inherit" to="/admin/dashboard">Dashboard</Link>
          <Link underline="hover" color="inherit" to={"/admin/ferry-ticket/list"}>Ferry Ticket</Link>
          <Typography color="text.primary">{isAddMode ? "Add" : "Edit"}</Typography>
        </Breadcrumbs>
      </div>
      
      <div className={`${styles.MainCard}`}>
        <div className={`${styles.AddUserMain}`}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup}`}>
                <label className={`${styles.FormLabel}`}>Name*</label>
                <input {...register("name")} className={`${styles.FormControl}`} />
                {errors.name && (<span className={`${styles.ErrorM}`}>{errors.name.message}</span>)}
              </div>
              <div className={`${styles.FormGroup}`}>
                <label className={`${styles.FormLabel}`}>Charge(WST)*</label>
                <Controller
                  control={control}
                  name="amount"
                  className={`${styles.FormControl}`}
                  render={({ field: { onChange, name, value } }) => (<NumericFormat
                    decimalScale={2}
                    name={name}
                    value={value}
                    onChange={onChange}
                    className={`${styles.FormControl}`}
                  />)}
                />
                {errors.amount && (<span className={`${styles.ErrorM}`}>{errors.amount.message}</span>)}
              </div>
            </div>

            <div>
              <div>
                <label className={`${styles.FormLabel}`}>Select Available Slots*</label>
                <div>
                    {fields?.map((field,index)=>{
                      return (<div>
                        <h5>{field?.label}</h5>
                        <div>
                          {timeSlots()?.map((item,subIndex)=>{
                            return <div className={watch('available_days') && getValues(`available_days.${index}.slots.${subIndex}`) ? 'one' : 'two'}>
                              <Checkbox {...register(`available_days.${index}.slots.${subIndex}`)} value={item?.value} defaultChecked={field?.slots && field?.slots[subIndex]} />  
                              {item?.label}
                            </div>
                          })}
                        </div>
                      </div>);
                    })}
                </div>
              </div>
            </div>
            
            <div className={`${styles.FormButtonArea}`}>
              <Link to={`/admin/ferry-ticket/list`} as={Button} className={`${styles.CancelBU}`}>Cancel</Link>
              <input className={`${styles.SubmitBU}`} value="Submit" type="submit" />
            </div>
          </form>
        </div>
      </div>
    </div>)}
  </React.Fragment>);
}

export default withAdminAuth(FerryTicketAdd);