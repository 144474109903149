import { useState } from "react";
import styles from "../reservation.module.css";
import { Button, Dialog, DialogContent, Link } from "@mui/material";
import moment from "moment";
import { AlertCircle, Calendar, X } from "react-feather";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CarBlock = ({isSelected, item, selectCar, currency, currencyRate, currencyLoading, isDisabled}) => {
    const [open, setOpen] = useState(false);
    const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
    
    const openDatePicker = () => {
        setDatePickerIsOpen((datePickerIsOpen) => !datePickerIsOpen);
    }
    
    return (<>
        <div className={`${styles.BigSideCarList}`}>
            <div className={isSelected ? `${styles.BigSideCarListUnder}  ${styles.Selected}` : `${styles.BigSideCarListUnder}`}>
                <img src={item.image_url} alt={item.title} />
                <div className={`${styles.ReservCarDeSec}`}>
                    <p className={`${styles.ReservCarDeTitle}`}>{item.title}</p>
                    <p className={`${styles.ReservCarDeSTitle}`}>{item.type}</p>
                    <div className={`${styles.CarFeatured}`}>
                        <span><img src="/images/auto.png" alt="" /> {item.transmission}</span>
                        <span><img src="/images/user.png" alt="" /> {item.passengers} People</span>
                        {(item.air_conditioning === 1 || item.air_conditioning === "1") && (<span><img src="/images/wind.png" alt="" /> A/C</span>)}
                        <span><img src="/images/brifcase.png" alt="" /> {item.luggage}</span>
                    </div>
                    <Link onClick={(e) => setOpen(true)} className={`${styles.CarInfoLink}`}><AlertCircle /> Important info</Link>
                    <Link onClick={openDatePicker} className={`${styles.CarInfoLink} ${styles.CarInfoLinkNew}`}><Calendar /> Availability</Link>
                    <div className="DatePickerNew">
                        <DatePicker
                            minDate={moment().toDate()}
                            onClickOutside={openDatePicker}
                            open={datePickerIsOpen}
                            excludeDates={item.booking_dates.map((i) => {
                                return moment(i).toDate();
                            })}
                        />
                    </div>
                </div>
                <div className={`${styles.ReservCarPriceSec}`}>
                    <p className={`${styles.ReservCarPrice}`}>{!currencyLoading && currency + " " + (item.price * currencyRate).toFixed(2)}</p>
                    <p className={`${styles.ReservCarPriceTwo}`}>Per Day</p>
                    {isDisabled ? (<button className={`${styles.HeaderBU} ${styles.HeaderBUDisabled}`} disabled={true}><span>Sold Out</span></button>) : isSelected ? (<button className={`${styles.HeaderBUSE}`}><span>Selected</span></button>) : (<button className={`${styles.HeaderBU}`} onClick={selectCar.bind(this, item)}><span>Select</span></button>)}
                </div>
            </div>
        </div>
        
        <Dialog
            maxWidth="md"
            open={open}
            onClose={(e)=> setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <div className={`${styles.ImportantInfoSec}`}>
                    <Button onClick={(e) => setOpen(false)} className={`${styles.InfoPopBU}`}><X /></Button>
                    <p className={`${styles.ImportantInfoTitle}`}>*Important information</p>
                    {item.drive_licence_req && (<div className={`${styles.ImpInfoRow}`}>
                        <div className={`${styles.ImpInfoSmallSec}`}>
                            <img src="/images/user-blue.png" alt="" />
                            <p className={`${styles.ImpInfoTitle}`}>*Drive & license requirements</p>
                        </div>
                        <div className={`${styles.ImpInfoBigSec}`}>
                            <div class={`${styles.ImpInfoBigSecText}`} dangerouslySetInnerHTML={{ __html: item.drive_licence_req }}></div>
                        </div>
                    </div>)}
                    {item.security_policy && (<div className={`${styles.ImpInfoRow}`}>
                        <div className={`${styles.ImpInfoSmallSec}`}>
                            <img src="/images/fuel-pump.png" alt="" />
                            <p className={`${styles.ImpInfoTitle}`}>Security Policy</p>
                        </div>
                        <div className={`${styles.ImpInfoBigSec}`}>
                            <div className={`${styles.ImpInfoBigSecText}`} dangerouslySetInnerHTML={{ __html: item.security_policy }}></div>
                        </div>
                    </div>)}
                    {item.insurance_coverage && (<div className={`${styles.ImpInfoRow}`}>
                        <div className={`${styles.ImpInfoSmallSec}`}>
                            <img src="/images/insurance.png" alt="" />
                            <p className={`${styles.ImpInfoTitle}`}>Insurance coverage</p>
                        </div>
                        <div className={`${styles.ImpInfoBigSec}`}>
                            <div className={`${styles.ImpInfoBigSecText}`} dangerouslySetInnerHTML={{__html: item.insurance_coverage}}></div>
                        </div>
                    </div>)}
                    {item.fuel_policy && (<div className={`${styles.ImpInfoRow}`}>
                        <div className={`${styles.ImpInfoSmallSec}`}>
                            <img src="/images/fuel-pump.png" alt="" />
                            <p className={`${styles.ImpInfoTitle}`}>Fuel Policy</p>
                        </div>
                        <div className={`${styles.ImpInfoBigSec}`}>
                            <div className={`${styles.ImpInfoBigSecText}`} dangerouslySetInnerHTML={{ __html: item.fuel_policy }}></div>
                        </div>
                    </div>)}
                    {item.mileage && (<div className={`${styles.ImpInfoRow}`}>
                        <div className={`${styles.ImpInfoSmallSec}`}>
                            <img src="/images/speedometer.png" alt="" />
                            <p className={`${styles.ImpInfoTitle}`}>Mileage</p>
                        </div>
                        <div className={`${styles.ImpInfoBigSec}`}>
                            <div className={`${styles.ImpInfoBigSecText}`} dangerouslySetInnerHTML={{ __html: item.mileage }}></div>
                        </div>
                    </div>)}
                    {item.cleaning_policy && (<div className={`${styles.ImpInfoRow}`}>
                        <div className={`${styles.ImpInfoSmallSec}`}>
                            <img src="/images/clean.png" alt="" />
                            <p className={`${styles.ImpInfoTitle}`}>Cleaning Policy</p>
                        </div>
                        <div className={`${styles.ImpInfoBigSec}`}>
                            <div className={`${styles.ImpInfoBigSecText}`} dangerouslySetInnerHTML={{ __html: item.cleaning_policy }}></div>
                        </div>
                    </div>)}
                </div>
            </DialogContent>
        </Dialog>
    </>);
}

export default CarBlock;