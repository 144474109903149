import React, {useState} from 'react';
import styles from './ferry-ticket.module.css';
import axios from 'axios';

const FerryTicket = () =>{
  const [bookingId, setBookingId] = useState('');

  const getBookingDetails = () =>{
    if(bookingId !== ''){
      axios(process.env.REACT_APP_API_URL + "v1/booking/" +bookingId).then((response) => {
        console.log(response.data.data);
      });
    }
  }

  return (<React.Fragment>
    <div className={`${styles.BookingDone}`}>
      <div>
        <label>Please enter booking id:</label>
        <input type="text" onChange={(e) => setBookingId(e.target.value)} />
        <input type="button" value="Add" onClick={(e)=> getBookingDetails()} />
       </div>
    </div>
  </React.Fragment>);
}

export default FerryTicket;